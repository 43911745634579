import * as moment from "moment";

export const today = moment().utc().locale("pt-br");

export function hasDecimal(num) {
  const numStr = num.toString();
  if (numStr.includes(".")) {
    return parseInt(numStr.split(".")[1]) > 0;
  }
  return false;
}

export function checkExpiredDate(date) {
  let today = moment().locale("pt-br").format("YYYY-MM-DD");
  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck < today;
}

export function dateIsToday(date) {
  if (!date) return false;

  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck == today.format("YYYY-MM-DD");
}

<template>
  <!-- MOBILE -->
  <v-navigation-drawer
    v-if="mobile"
    app
    clipped
    v-model="drawer"
    width="300"
    :color="$vuetify.theme.dark ? 'background' : ''"
  >
    <div class="d-flex flex-column" style="height: 100%">
      <v-list>
        <Logo
          product="conecta_control"
          full
          width="160"
          noMargins
          :white="$vuetify.theme.dark"
          class="ml-4 mt-0"
        />
        <v-card
          outlined
          v-if="controlInTrial && $vuetify.breakpoint.lgAndDown"
          class="px-4 pb-4 font-weight-medium caption mx-4 mb-2"
        >
          <v-card-subtitle class="px-0 font-weight-medium">
            {{ $ml.get("testing_text") }}
          </v-card-subtitle>
          <TrialBar v-if="controlInTrial" show :mobile="mobile" />
        </v-card>
        <v-list shaped dense>
          <div v-for="item in items" :key="item.title_tag" v-show="item.show">
            <v-list-group v-if="item.submenu" no-action>
              <template v-slot:activator>
                <v-list-item class="ma-0 px-0" color="primary">
                  <v-list-item-icon style="max-width: 24px">
                    <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                    <Logo
                      v-if="item.logo"
                      :product="item.logo"
                      height="24"
                      :white="$vuetify.theme.dark"
                      noMargins
                    />
                  </v-list-item-icon>
                  <v-list-item-content class="text-left">
                    <v-list-item-title>{{
                      $ml.get(item.title_tag)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-for="subItem in item.submenu"
                v-show="subItem.show"
                :key="item.title_tag + subItem.title_tag"
                :to="subItem.to"
                :href="subItem.href"
                :target="subItem.blank"
                class="pl-10"
                link
                color="primary"
              >
                <v-list-item-icon style="max-width: 24px">
                  <v-icon v-if="subItem.icon">{{ subItem.icon }}</v-icon>
                  <Logo
                    v-if="subItem.logo"
                    :product="subItem.logo"
                    height="24"
                    :white="$vuetify.theme.dark"
                    noMargins
                  />
                </v-list-item-icon>
                <v-list-item-content class="text-left">
                  <v-list-item-title>{{
                    $ml.get(subItem.title_tag)
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="subItem.logo">
                  <v-icon v-text="'mdi-open-in-new'" small />
                </v-list-item-action>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :to="item.to"
              :href="item.href"
              :target="item.blank"
              link
              color="primary"
            >
              <v-list-item-icon style="max-width: 24px">
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <Logo
                  v-if="item.logo"
                  :product="item.logo"
                  height="24"
                  :white="$vuetify.theme.dark"
                  noMargins
                />
              </v-list-item-icon>
              <v-list-item-content class="text-left">
                <v-list-item-title>{{
                  $ml.get(item.title_tag)
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.logo">
                <v-icon v-text="'mdi-open-in-new'" small />
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
      </v-list>
      <v-spacer />
      <v-list>
        <v-list dense rounded :class="`rounded-lg`">
          <v-list-item
            v-if="hasNoIntermediaryPartner && !mini"
            :class="`py-0 mb-2 rounded-xl pl-2`"
            style="background-color: #31a753 !important"
            @click="goToSelfCheckout"
          >
            <v-list-item-action :class="`ml-2 mr-7 px-0 ml-0`">
              <v-icon v-text="'mdi-store-plus'" color="white" size="25" />
            </v-list-item-action>
            <v-list-item-content class="pa-0 ma-0">
              <v-list-item-title
                class="text-body-2 white--text font-weight-bold px-0 mx-0"
              >
                {{ $ml.get("new_service_plan") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-row class="px-0 mx-0 mt-2 mb-5">
          <v-list-item @click="initChat" link dense class="mb-2">
            <v-list-item-icon>
              <v-icon v-text="'mdi-face-agent'" />
            </v-list-item-icon>
            <v-list-item-content class="text-left">
              <v-list-item-title v-if="drawer">
                {{ $ml.get("chat_support") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div class="d-flex flex-column mt-2">
            <div class="caption mx-4 d-flex align-center">
              <v-icon v-text="'mdi-copyright'" small class="mr-1" />
              Conecta Nuvem
            </div>
            <v-row class="mx-2 mt-2">
              <v-btn
                x-small
                color="primary"
                class="caption text-none font-weight-medium"
                text
                href="https://conectasuite.com/politica-de-privacidade"
                target="_blank"
              >
                {{ $ml.get("privacy_policy") }}
              </v-btn>
              <v-icon v-text="'mdi-circle-small'" small />
              <v-btn
                x-small
                color="primary"
                class="caption text-none font-weight-medium"
                text
                href="https://conectasuite.com/termos-de-uso"
                target="_blank"
              >
                {{ $ml.get("terms_of_use") }}
              </v-btn>
            </v-row>
          </div>
        </v-row>
      </v-list>
    </div>
  </v-navigation-drawer>
  <!-- DESKTOP -->
  <v-navigation-drawer
    v-else
    app
    clipped
    v-model="drawer"
    mini-variant-width="70"
    :mini-variant.sync="mini"
    :permanent="showPermanent && !$vuetify.breakpoint.mdAndDown"
    :expand-on-hover="mini"
    width="300"
    :color="$vuetify.theme.dark ? 'background' : ''"
    :style="mini ? 'border-right: 1px solid #ddd; border-left: 0px' : ''"
  >
    <div class="d-flex flex-column fill-height">
      <v-list :shaped="!mini" :rounded="mini" dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <div v-for="item in items" :key="item.title_tag" v-show="item.show">
            <v-list-group v-if="item.submenu" no-action>
              <template v-slot:activator>
                <v-list-item
                  :class="`${mini && drawer ? 'px-0 ' : 'px-2 '}py-1 my-0`"
                  :ripple="false"
                  :inactive="true"
                >
                  <v-list-item-icon style="max-width: 24px">
                    <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                    <Logo
                      v-if="item.logo"
                      :product="item.logo"
                      height="24"
                      :white="$vuetify.theme.dark"
                      noMargins
                    />
                  </v-list-item-icon>
                  <v-list-item-content class="text-left">
                    <v-list-item-title>{{
                      $ml.get(item.title_tag)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-for="subItem in item.submenu"
                v-show="subItem.show"
                :key="item.title_tag + subItem.title_tag"
                :to="subItem.to"
                :href="subItem.href"
                :target="subItem.blank"
                class="pl-10 py-1"
              >
                <v-list-item-icon style="max-width: 24px">
                  <v-icon v-if="subItem.icon">{{ subItem.icon }}</v-icon>
                  <Logo
                    v-if="subItem.logo"
                    :product="subItem.logo"
                    height="24"
                    :white="$vuetify.theme.dark"
                    noMargins
                  />
                </v-list-item-icon>
                <v-list-item-content class="text-left">
                  <v-list-item-title>{{
                    $ml.get(subItem.title_tag)
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="subItem.logo">
                  <v-icon v-text="'mdi-open-in-new'" small />
                </v-list-item-action>
                <v-list-item-action
                  class="my-0"
                  style="min-width: auto !important"
                  v-if="subItem.beta"
                >
                  <v-chip
                    color="success"
                    dark
                    title="Disponível por tempo limitado!"
                    x-small
                    class="font-weight-medium"
                    >Beta
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :to="item.to"
              :href="item.href"
              :target="item.blank"
              :class="`${!mini ? 'pl-6' : ''} py-1`"
            >
              <v-list-item-icon style="max-width: 24px">
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <Logo
                  v-if="item.logo"
                  :product="item.logo"
                  height="24"
                  :white="$vuetify.theme.dark"
                  noMargins
                />
              </v-list-item-icon>
              <v-list-item-content class="text-left">
                <v-list-item-title>{{
                  $ml.get(item.title_tag)
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.logo">
                <v-icon v-text="'mdi-open-in-new'" small />
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>

      <v-spacer></v-spacer>

      <div>
        <v-list dense rounded :class="`rounded-lg`">
          <v-list-item
            v-if="hasNoIntermediaryPartner && !mini"
            :class="`py-0 mb-2 rounded-xl pl-2`"
            style="background-color: #31a753 !important"
            @click="goToSelfCheckout"
          >
            <v-list-item-action :class="`ml-2 mr-7 px-0 ml-0`">
              <v-icon v-text="'mdi-store-plus'" color="white" size="25" />
            </v-list-item-action>
            <v-list-item-content class="pa-0 ma-0">
              <v-list-item-title
                class="text-body-2 white--text font-weight-bold px-0 mx-0"
              >
                {{ $ml.get("new_service_plan") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- VERSÃO REDUZIDA -->
          <v-list-item
            v-else-if="hasNoIntermediaryPartner && mini"
            style="background-color: #31a753 !important"
            class="py-0 mt-1 rounded-xl mx-2"
            @click="goToSelfCheckout"
          >
            <v-list-item-icon class="ml-0">
              <v-icon v-text="'mdi-store-plus'" color="white" />
            </v-list-item-icon>
            <v-list-item-title class="white--text">
              {{ $ml.get("new_service_plan") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list shaped dense>
          <v-list-item
            v-if="!mini"
            link
            dense
            :class="`feedback-button ${!mini ? 'pl-6' : 'ml-2'} py-0`"
            @click="setPreviewedFeedback"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-message-alert-outline'" />
            </v-list-item-icon>
            <v-list-item-content class="text-left">
              <v-list-item-title v-if="!mini || drawer">
                {{ $ml.get("send_feedback") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="is_support_chat_available && !isSmallClient"
            @click="initChat"
            link
            dense
            :class="`${!mini ? 'pl-6' : 'ml-2'} py-0 mt-1`"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-face-agent'" />
            </v-list-item-icon>
            <v-list-item-content class="text-left">
              <v-list-item-title v-if="!mini || drawer">
                {{ $ml.get("chat_support") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row class="px-0 mx-0 mt-0 mb-3 pl-4">
          <div v-if="!mini" class="d-flex flex-column pt-2">
            <div class="caption d-flex align-center ml-2">
              <v-icon v-text="'mdi-copyright'" small class="mr-1" />
              Conecta Nuvem
            </div>
            <v-row class="mx-2 mt-2 mb-0">
              <v-btn
                x-small
                color="primary"
                class="caption text-none font-weight-medium pr-1 pl-0"
                text
                href="https://conectasuite.com/politica-de-privacidade"
                target="_blank"
              >
                {{ $ml.get("privacy_policy") }}
              </v-btn>
              <v-icon v-text="'mdi-circle-small'" small />
              <v-btn
                x-small
                color="primary"
                class="caption text-none font-weight-medium px-0"
                text
                href="https://conectasuite.com/termos-de-uso"
                target="_blank"
              >
                {{ $ml.get("terms_of_use") }}
              </v-btn>
            </v-row>
          </div>
        </v-row>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Logo from "@/components/base/Logo";
import { companyProfileLink, suiteLink } from "@/helpers/links";
import { mapGetters, mapMutations, mapActions } from "vuex";
import TrialBar from "@/components/main/TrialBar";
import { initFreshChat, isNuvemClient } from "@/helpers/utils";
export default {
  name: "Sidebar",
  components: { Logo, TrialBar },
  props: {
    show: { type: Boolean, required: true },
    showPermanent: { type: Boolean, default: true },
    showMini: { type: Boolean, degfault: false },
    mobile: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedItem: false,
      suiteLink,
      companyProfileLink,
    };
  },
  computed: {
    ...mapGetters([
      "isSsoActive",
      "loading",
      "companyFreshChatConfig",
      "companyInSuite",
      "controlInTrial",
      "isMicrosoft",
      "hasScheduleActivationInfo",
      "is_support_chat_available",
      "isSmallClient",
    ]),
    hasNoIntermediaryPartner() {
      if (this.companyInSuite && this.companyInSuite.reseller_company) {
        return this.companyInSuite.reseller_company.type != "INTERMEDIARY";
      } else {
        return false;
      }
    },
    showActivationPage() {
      return this.loading
        ? false
        : !this.isSsoActive && !this.hasScheduleActivationInfo;
    },
    items() {
      return [
        {
          icon: "mdi-home",
          to: "/",
          title_tag: "home",
          show: true,
        },
        {
          icon: "mdi-power",
          to: "/activation-steps",
          title_tag: "active_control",
          show: this.showActivationPage,
        },
        {
          icon: "mdi-account-multiple",
          title_tag: "users",
          to: "/users",
          show: true,
        },
        {
          icon: "mdi-store",
          title_tag: "company_profile",
          href: `${companyProfileLink}`,
          blank: "_blank",
          show: true,
        },
        {
          icon: "mdi-account-key",
          title_tag: "user_permissions",
          to: "/admin",
          show: true,
        },
        {
          icon: "mdi-briefcase-clock",
          title_tag: "groups",
          to: "/groups",
          show: true,
        },
        {
          icon: "mdi-lock",
          title_tag: "password_settings",
          to: "/password-settings",
          show: true,
        },
        {
          title_tag: "conecta_suite",
          logo: "conecta_suite",
          href: `${suiteLink}`,
          blank: "_blank",
          show: true,
        },
        {
          icon: "mdi-poll",
          title_tag: "reports",
          show: true,
          submenu: [
            {
              icon: "mdi-briefcase-arrow-up-down",
              title_tag: "reports_loginlogout",
              to: "/reports/loginlogout",
              show: true,
            },
            {
              icon: "mdi-briefcase-account",
              title_tag: "reports_access_time",
              to: "/reports/access_duration",
              show: true,
            },
            {
              icon: "mdi-account",
              title_tag: "reports_users",
              to: "/reports/users",
              show: true,
            },
            {
              icon: "mdi-briefcase-clock",
              title_tag: "reports_groups",
              to: "/reports/groups",
              show: true,
            },
            {
              icon: "mdi-google-drive",
              title_tag: "folders_sharing",
              to: "/reports/folders-sharing",
              show: !this.isMicrosoft,
              beta: true,
            },
            {
              icon: "mdi-account-key",
              title_tag: "permissions",
              to: "/reports/user_permissions",
              show: true,
            },
            {
              icon: "mdi-tools",
              title_tag: "customize_login_label",
              to: "/reports/customize_login",
              show: true,
            },
          ],
        },
        {
          icon: "mdi-shape-square-rounded-plus",
          title_tag: "integrations",
          to: "/integrations",
          show: true,
        },
        {
          icon: "mdi-tools",
          title_tag: "customize_login",
          to: "/customize",
          show: true,
        },
        { icon: "mdi-alert", title_tag: "alerts", show: false },
      ].filter((item) => item.show);
    },
    drawer: {
      get() {
        return this.show;
      },
      set(value) {
        !value != !this.show && this.$emit("show", value);
      },
    },
    mini: {
      get() {
        return this.showMini;
      },
      set(value) {
        !value != !this.showMini && this.$emit("showMini", value);
      },
    },
  },
  methods: {
    ...mapMutations(["setPreviewedFeedback"]),
    ...mapActions(["selfcheckoutRedirect", "getHubspotToken"]),
    initFreshChat,
    isNuvemClient,

    async initChat() {
      const { reseller_token } = this.companyInSuite;
      if (this.isNuvemClient(reseller_token)) {
        this.initFreshChat();
        return;
      }
      await this.getHubspotToken();
    },

    goToSelfCheckout() {
      if (this.isMicrosoft) {
        this.$router.push({ name: "BillingUpgradePlan" });
      } else {
        this.selfcheckoutRedirect();
      }
    },
  },
};
</script>

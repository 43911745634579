<template>
  <v-app-bar
    :color="`${$vuetify.theme.dark ? 'background' : 'primary'}`"
    :style="`${
      $vuetify.theme.dark ? 'border-bottom: 1px solid #424242 !important' : ''
    }`"
    clipped-left
    app
    dark
    flat
    class="pl-1"
  >
    <v-app-bar-nav-icon
      v-if="!showSearchBar && !isUpgradePlanPage"
      @click.stop="openOrCloseSidebar"
    />
    <v-app-bar-nav-icon
      v-else-if="isUpgradePlanPage"
      style="margin-left: -12px"
    >
      <template #default>
        <v-btn icon @click="goToHomePage" :ripple="false">
          <v-icon v-text="'mdi-home'" />
        </v-btn>
      </template>
    </v-app-bar-nav-icon>

    <!-- TOOLBAR -->
    <v-toolbar-title
      v-if="!showSearchBar"
      :style="`${
        $vuetify.breakpoint.smAndDown ? 'width: 80px;' : 'width: 268px;'
      }`"
      :class="`d-flex align-center ml-0 pl-5`"
    >
      <span
        style="max-width: 30px"
        v-if="$vuetify.breakpoint.smAndDown"
        :class="`${$route.name !== 'Home' ? 'link' : ''}`"
        @click="goToHomePage()"
      >
        <Logo
          product="conecta_control"
          height="28"
          width="28"
          noMargins
          white
        />
      </span>
      <span
        v-else
        :class="`text-decoration-none white--text ${
          $route.name !== 'Home' ? 'link' : ''
        }`"
        @click="goToHomePage()"
      >
        <Logo
          product="conecta_control_without_icon"
          width="200"
          noMargins
          white
          full
        />
      </span>
    </v-toolbar-title>

    <!-- Search input -->
    <v-combobox
      class="hidden-sm-and-down"
      v-model="search"
      item-text="text"
      :label="$ml.get('global_search')"
      color="primary"
      item-color="grey darken-1"
      flat
      hide-no-data
      hide-details
      :solo-inverted="!$vuetify.theme.dark"
      :solo="$vuetify.theme.dark"
      prepend-inner-icon="mdi-magnify"
      :items="itemsToSearch"
      return-object
      :filter="comboboxFilter"
    >
      <template v-slot:item="{ on, item }">
        <v-list-item v-on="on" @click="setSearchOption(item)">
          <v-list-item-content>
            <v-list-item-title>{{ item.text }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>

    <v-expand-x-transition>
      <v-combobox
        v-if="showSearchBar"
        v-model="search"
        item-text="text"
        :label="$ml.get('global_search')"
        color="primary"
        flat
        hide-no-data
        hide-details
        solo
        :light="!$vuetify.theme.dark"
        :dark="$vuetify.theme.dark"
        :items="itemsToSearch"
        return-object
        :filter="comboboxFilter"
      >
        <template v-slot:prepend-inner>
          <v-btn
            icon
            @click="showSearchBar = false"
            v-if="$vuetify.breakpoint.smAndDown && showSearchBar"
          >
            <v-icon v-text="'mdi-arrow-left'" />
          </v-btn>
        </template>

        <template v-slot:item="{ on, item }">
          <v-list-item v-on="on" @click="setSearchOption(item)">
            <v-list-item-content>
              <v-list-item-title>{{ item.text }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-combobox>
    </v-expand-x-transition>
    <v-spacer />
    <v-chip
      v-if="
        !isSmallClient &&
        controlInTrialOrInexistent &&
        !IsScheduleMeetingSnackbarVisible &&
        !loading &&
        ($vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl)
      "
      class="pl-0"
      @click="scheduleMeeting"
      outlined
      pill
    >
      <v-badge color="green" dot bottom offset-x="10" offset-y="10">
        <v-avatar>
          <v-img
            aspect-ratio="1/1"
            :src="commercial_responsable_img"
            alt="user photo"
          ></v-img>
        </v-avatar>
      </v-badge>
      <span class="ml-4">{{ $ml.get("schedule_meeting") }}</span>
    </v-chip>
    <TrialBar v-if="$vuetify.breakpoint.lgAndUp" :show="controlInTrial" />

    <v-btn
      icon
      class="mr-2 hidden-md-and-up"
      @click="showSearchBar = true"
      v-if="!showSearchBar"
    >
      <v-icon v-text="'mdi-magnify'" />
    </v-btn>

    <!-- BOTAO REFRESH PAGE -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          icon
          class="mr-2 hidden-sm-and-down"
          v-on="{ ...tooltip }"
          @click="setInitialContent()"
          :loading="loadingGroups || loadingUsers"
        >
          <v-icon v-text="'mdi-reload'" />
        </v-btn>
      </template>
      <span>{{ $ml.get("update") }}</span>
    </v-tooltip>
    <!-- BOTAO DE AJUDA -->
    <v-tooltip left>
      <template v-slot:activator="{ on: tooltip }">
        <v-menu
          v-on="{ ...tooltip }"
          left
          bottom
          offset-y
          close-on-content-click
        >
          <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon class="mr-2" v-on="{ ...tooltip, ...menu }">
                  <v-icon v-text="'mdi-help-circle-outline'" />
                </v-btn>
              </template>
              <span>{{ $ml.get("support") }}</span>
            </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(option, i) in helpMenu"
              :key="i"
              link
              @click="option.action"
              :three-line="option.subtitle ? true : false"
              class="feedback-button"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="mb-0 pb-0 font-weight-regular text-body-2"
                  >{{ $ml.get(option.title) }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="font-weight-regular mt-0"
                  v-if="option.subtitle"
                  >{{ $ml.get(option.subtitle) }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-tooltip>

    <!-- BOTÃO DE CONFIGURAÇÕES -->
    <v-menu bottom offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              id="v-step-7"
              icon
              class="mr-2"
              v-on="{ ...tooltip, ...menu }"
            >
              <v-icon v-text="'mdi-cog-outline'" />
            </v-btn>
          </template>
          <span>{{ $ml.get("settings") }}</span>
        </v-tooltip>
      </template>
      <v-card tile width="350" class="px-2 pb-4">
        <v-card-title class="text-body-1 font-weight-medium">{{
          $ml.get("settings")
        }}</v-card-title>

        <v-list dense subheader class="mx-2">
          <v-list-item
            v-if="isSsoActive || company.new_sso_active"
            class="body-2 mt-0 mb-2"
            :disabled="loadingSsoStatus"
            link
            @click="startSSO()"
          >
            <v-list-item-icon class="mr-4 px-0">
              <v-progress-circular
                v-if="loadingSsoStatus"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon
                v-else
                :disabled="loadingSsoStatus"
                v-text="'mdi-power-standby'"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $ml.get("inactive_control") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-else-if="!hasScheduleActivationInfo"
            class="body-2 mt-0 mb-2"
            :disabled="loadingSsoStatus"
            link
            @click="startSSO()"
          >
            <v-list-item-icon class="mr-4 px-0">
              <v-progress-circular
                v-if="loadingSsoStatus"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon
                v-else
                :disabled="loadingSsoStatus"
                v-text="'mdi-power-standby'"
              />
            </v-list-item-icon>

            <!-- ACTIVATE CONTROL -->
            <v-list-item-content>
              <v-list-item-title>
                {{ $ml.get("active_control") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-card-title class="text-body-1 font-weight-medium mb-0">{{
          $ml.get("customize")
        }}</v-card-title>
        <v-list dense subheader class="mx-2">
          <v-subheader class="caption font-weight-medium text-uppercase">{{
            $ml.get("theme")
          }}</v-subheader>
          <v-list-item class="text-body-2 mt-0" link @click="setDarkMode()">
            <v-list-item-icon class="mr-4 px-0">
              <v-icon
                v-text="
                  $vuetify.theme.dark
                    ? 'mdi-white-balance-sunny'
                    : 'mdi-weather-night'
                "
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{
                  $vuetify.theme.dark
                    ? $ml.get("active_light_theme")
                    : $ml.get("active_dark_theme")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader class="caption font-weight-medium text-uppercase">{{
            $ml.get("language")
          }}</v-subheader>
          <v-menu offset-x left :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item class="text-body-2 mt-0" v-bind="attrs" v-on="on">
                <v-list-item-icon class="mr-4 px-0">
                  <v-icon v-text="'mdi-translate'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentLanguage }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="ml-auto">
                  <v-icon v-text="'mdi-chevron-right'" />
                </v-list-item-icon>
              </v-list-item>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in allLanguages"
                :key="index"
                @click="changeLanguage(item.lang)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </v-card>
    </v-menu>

    <!-- APPS -->
    <v-menu
      v-if="!$vuetify.breakpoint.xs"
      offset-y
      left
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="mr-2" v-bind="attrs" v-on="on">
          <v-icon v-text="'mdi-apps'" />
        </v-btn>
      </template>
      <v-card width="220" class="pb-8 pt-1">
        <v-card-subtitle>
          {{ $ml.get("another_products") }}
        </v-card-subtitle>
        <v-row class="d-flex align-center px-5">
          <v-col
            class="text-center"
            cols="12"
            v-for="product in products"
            :key="product"
          >
            <Logo
              :product="product"
              link
              height="40"
              :white="$vuetify.theme.dark"
            />
            <small>{{
              product == "conecta_suite"
                ? $ml.get("conecta_suite")
                : $ml.get("conecta_sign")
            }}</small>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>

    <!-- BOTAO DE SAIR -->
    <v-menu offset-y left :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="32" v-bind="attrs" v-on="on" class="mr-2">
          <img
            :src="currentUser.photo || userDefaultImage"
            :alt="currentUser.username"
          />
        </v-avatar>
      </template>
      <v-card width="350" class="py-4">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar size="80">
            <img
              :src="currentUser.photo || userDefaultImage"
              :alt="currentUser.username"
            />
          </v-avatar>
          <v-card-title>{{ currentUser.username }}</v-card-title>
          <v-card-subtitle>{{ currentUser.email }}</v-card-subtitle>
          <v-btn
            small
            text
            outlined
            rounded
            class="text-none text-body-2 px-4 py-2"
            :href="`${suiteLink}`"
            :target="'_blank'"
          >
            {{ $ml.get("account_manager") }}
          </v-btn>
        </v-card-text>
        <v-divider />
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-btn text outlined @click="logout">
            {{ $ml.get("sign_out") }}
          </v-btn>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex align-center justify-center pt-4">
          <v-btn
            x-small
            color="grey darken-2"
            class="caption text-none"
            text
            href="https://conectasuite.com/politica-de-privacidade"
            target="_blank"
          >
            {{ $ml.get("privacy_policy") }}
          </v-btn>
          <v-icon v-text="'mdi-circle-small'" small />
          <v-btn
            x-small
            color="grey darken-2"
            class="caption text-none"
            text
            href="https://conectasuite.com/termos-de-uso"
            target="_blank"
          >
            {{ $ml.get("terms_of_use") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <HeaderDialog
      id="inactive-control"
      :title="$ml.get('disable_conecta_control_dialog_title')"
      :show="showInactivateControlDialog"
      width="600"
      :actionText="$ml.get('disable_control')"
      :loading="loadingSsoStatus"
      :disabledAction="!isChallengeCorrect || challengeAnswer === ''"
      @close="closeInactivateControlDialog"
      @action="disableConectaControlSSO"
      closeText="cancel"
    >
      <template v-slot:body>
        <p class="pb-2">
          {{ $ml.get("confirm_deactivate_message") }}
        </p>

        <p
          class="outlined rounded pa-5 text-center font-weight-medium text-h5 grid"
        >
          {{ mathProblem }}
        </p>
        <v-text-field
          v-model="challengeAnswer"
          :placeholder="$ml.get('your_answer')"
          persistent-placeholder
          :hint="$ml.get('challenge_input_hint')"
          type="number"
          :rules="[
            checkChallengeRule || $ml.get('challenge_input_hint_validation'),
          ]"
          @click="checkChallengeRule = true"
          :disabled="isChallengeCorrect"
          :clearable="!isChallengeCorrect"
          hide-spin-buttons
          :loading="pageLoading"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <v-icon
                v-if="isChallengeCorrect"
                v-text="'mdi-check-circle'"
                color="success"
              />
            </v-fade-transition>
          </template>
        </v-text-field>
        <v-btn
          :loading="pageLoading"
          small
          block
          elevation="0"
          :disabled="isChallengeCorrect || challengeAnswer === ''"
          color="accent"
          class="mt-3"
          @click="validateProblem"
          >{{ $ml.get("verify") }}
        </v-btn>
      </template>
    </HeaderDialog>

    <HeaderDialog
      id="inform-disable"
      :title="$ml.get('disable_conecta_control_dialog_title')"
      :show="showDisableControlDialog"
      width="600"
      @close="showDisableControlDialog = false"
      @action="showChallenge"
    >
      <template v-slot:body>
        <v-row>
          <v-col cols="12">
            <Alert type="warning" :prominent="false">
              {{ $ml.get("disable_conecta_control_warning") }}
            </Alert>
          </v-col>
          <v-col>
            <p class="mb-0">
              {{ $ml.get("disable_conecta_control_description") }}
            </p>
            <p>
              {{ $ml.get("disable_conecta_control_description2") }}
            </p>
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>
  </v-app-bar>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import HeaderDialog from "@/components/base/HeaderDialog";
import TrialBar from "@/components/main/TrialBar";
import {
  frequentlyAskedQuestions,
  suiteLink,
  calendarSchedule,
  commercial_responsable_img,
} from "@/helpers/links";
import { sendScheduleEmail, removeAccents } from "@/helpers/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
export default {
  name: "Navbar",
  components: { HeaderDialog, TrialBar },
  data() {
    return {
      showSearchBar: false,
      showInactivateControlDialog: false,
      showDisableControlDialog: false,
      checkChallengeRule: true,
      isChallengeCorrect: false,
      loadingSsoStatus: false,
      challengeAnswer: "",
      pageLoading: false,
      mathResult: "",
      mathProblem: "",
      search: null,
      suiteLink,
      commercial_responsable_img,
    };
  },
  computed: {
    ...mapGetters([
      "currentLanguage",
      "allLanguages",
      "products",
      "users",
      "groups",
      "currentUser",
      "loadingUsers",
      "loadingGroups",
      "isSsoActive",
      "userDefaultImage",
      "is_support_chat_available",
      "controlInTrialOrInexistent",
      "company",
      "IsScheduleMeetingSnackbarVisible",
      "loading",
      "controlInTrial",
      "hasScheduleActivationInfo",
      "isSmallClient",
    ]),

    helpMenu() {
      return [
        {
          title: "chat_support",
          subtitle: "receive_support_label",
          action: () => this.$emit("openChat"),
          show: this.is_support_chat_available && !this.isSmallClient,
        },
        { title: "startTutorial", action: () => this.startTour(), show: true },
        {
          title: "frequently_questions",
          action: () => window.open(frequentlyAskedQuestions, "_blank"),
          show: true,
        },
        {
          title: "send_feedback",
          action: () => this.sendFeedback(),
          show: true,
        },
      ].filter((menuItem) => menuItem.show);
    },

    searchGroups() {
      return this.groups.map((group) => {
        return { text: group.name, value: group.key };
      });
    },

    searchUsers() {
      return this.users.map((user) => {
        return {
          text: user.name || user.username,
          value: user.key,
          email: user.email,
        };
      });
    },

    searchSettings() {
      const settings = [
        {
          text: `${this.$ml.get("theme")}: ${
            this.$vuetify.theme.dark
              ? this.$ml.get("active_light_theme")
              : this.$ml.get("active_dark_theme")
          }`,
          value: "change_theme",
        },
        {
          text: `${this.$ml.get("settings")}: ${
            !this.isSsoActive
              ? this.$ml.get("active_control")
              : this.$ml.get("inactive_control")
          }`,
          value: "activation",
        },
      ];

      if (this.hasScheduleActivationInfo) {
        settings.pop(1);
      }

      return settings;
    },

    searchHelp() {
      return [
        {
          text: this.$ml.get("button_feedback"),
          value: "help",
        },
      ];
    },

    itemsToSearch() {
      let search = [];
      search.push({ header: this.$ml.get("users") });
      Array.prototype.push.apply(search, this.searchUsers);
      search.push({ divider: true });
      search.push({ header: this.$ml.get("groups") });
      Array.prototype.push.apply(search, this.searchGroups);
      search.push({ divider: true });
      search.push({ header: this.$ml.get("settings") });
      Array.prototype.push.apply(search, this.searchSettings);
      /* search.push({ divider: true });
      search.push({ header: this.$ml.get("support") });
      Array.prototype.push.apply(search, this.searchHelp); */
      return search;
    },

    isUpgradePlanPage() {
      return this.$route.name === "BillingUpgradePlan";
    },
  },
  watch: {
    showInactivateControlDialog() {
      if (this.showInactivateControlDialog) {
        this.showChallenge();
      }
    },
  },
  methods: {
    ...mapMutations([
      "setLanguage",
      "setPreviewedFeedback",
      "updateCompanyNewSsoIsActive",
    ]),
    ...mapActions([
      "setLoading",
      "setInitialContent",
      "logout",
      "disableControl",
    ]),

    startSSO() {
      if (!(this.isSsoActive || this.company.new_sso_active)) {
        this.$router.push("/activation-steps");
        return;
      }

      this.showInactivateControlDialog = true;
    },

    scheduleMeeting() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "opened from navbar";

      logEvent(analytics, "schedule_meeting", {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });

      sendScheduleEmail();
      window.open(calendarSchedule, "_blank");
    },

    sendFeedback() {
      this.setPreviewedFeedback();

      const feedbackButton =
        document.getElementsByClassName("feedback-button")[0];

      feedbackButton.dispatchEvent(new Event("click"));
    },

    startTour() {
      if (this.$route.name !== "Home") {
        this.$router.push("/");

        const id = setTimeout(() => {
          this.$tours["onBoarding"].start();
          clearTimeout(id);
        }, 500);
      } else {
        this.$tours["onBoarding"].start();
      }
    },

    goToHomePage() {
      if (this.$route.name !== "Home") {
        this.$router.push("/");
      }
    },

    async setSearchOption(option) {
      if (option) {
        const value = option.value;
        const isCurrentPage = (value, param) =>
          value === this.$route.params[param];

        if (this.searchUsers.find((user) => user.value === value)) {
          if (!isCurrentPage(value, "id")) {
            this.$router.push(`/users/${value}`);
          }
        } else if (this.searchGroups.find((group) => group.value === value)) {
          if (!isCurrentPage(value, "key")) {
            this.$router.push(`/groups/${value}`);
          }
        } else if (value === "change_theme") {
          this.setDarkMode();
        } else if (value === "activation") {
          if (this.isSsoActive) {
            this.showDisableControlDialog = true;
          } else {
            this.$router.push("/activation-steps");
          }
        }
      }
    },

    setDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        "darkMode",
        JSON.stringify(this.$vuetify.theme.dark)
      );
    },

    changeLanguage(lang) {
      this.setLoading(2000);
      this.$ml.change(lang);
      this.setLanguage(lang);
    },

    openOrCloseSidebar() {
      this.$emit("changeSidebarStatus");
    },

    showChallenge() {
      const operators = {
        "+": (a, b) => a + b,
        "-": (a, b) => a - b,
      };
      const random = () => Math.floor(Math.random() * (100 - 1)) + 1;
      const randomOP = () => {
        let keys = Object.keys(operators);
        return keys[Math.round(Math.random())];
      };

      let num1 = random();
      let num2 = random();

      let operator = randomOP();

      this.mathResult = operators[operator](num1, num2);
      this.mathProblem = `${num1} ${operator} ${num2}`;
      this.showDisableControlDialog = false;
      this.showInactivateControlDialog = true;
    },

    validateProblem() {
      this.pageLoading = true;

      setTimeout(() => {
        this.isChallengeCorrect =
          this.mathResult == this.challengeAnswer ? true : false;
        this.checkChallengeRule = this.isChallengeCorrect;
        this.pageLoading = false;
      }, 1000);
    },

    closeInactivateControlDialog() {
      this.showInactivateControlDialog = false;
      this.isChallengeCorrect = false;
      this.challengeAnswer = "";
      localStorage.removeItem("activationProgress");
    },

    async disableConectaControlSSO() {
      this.loadingSsoStatus = true;
      await this.disableControl().then(() => {
        this.updateCompanyNewSsoIsActive(false);
      });
      this.loadingSsoStatus = false;
      this.closeInactivateControlDialog();
    },

    comboboxFilter(item, search, itemText) {
      const normalizedItemText = removeAccents(itemText)
        .toLocaleLowerCase()
        .trim();
      const normalizedSearch = removeAccents(search).toLocaleLowerCase().trim();

      if (item.email) {
        const normalizedItemEmail = removeAccents(item.email)
          .toLocaleLowerCase()
          .trim();
        return (
          normalizedItemText.indexOf(normalizedSearch) > -1 ||
          normalizedItemEmail.indexOf(normalizedSearch) > -1
        );
      }

      return normalizedItemText.indexOf(normalizedSearch) > -1;
    },
  },
};
</script>

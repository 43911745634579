const general = {
  total: "Total",
  recommended: "Recomendado",
  attention: "Atención",
  settings: "Ajustes",
  customize: "Personalizar",
  check_payment: `Para revisar tus pagos, accede a tu <strong><a class="font-weight-bold" href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/" target="_blank"> Área de cliente</a></strong> en Superlógica.`,
  check_payment_mistake:
    "If you have already made the payment and believe there might have been a mistake, please contact financeiro@conectasuite.com.",
  language: "Idioma",
  theme: "Tema",
  google_admin: "Admin Google",
  coming_soon: "Próximamente",
  try_new_sso: "Prueba el nuevo Control",
  schedule_meeting_button: "Chatea con el equipo",
  successful_activation_title: "¡Éxito! control de acceso habilitado",
  successful_activation_description_title_1:
    "Ahora para acceder a Workspace, los usuarios deben seguir las reglas configuradas en sus respectivos turnos",
  successful_activation_description_title_2:
    "Si no han configurado una contraseña, los próximos usuarios deben utilizar la contraseña temporal para acceder",
  successful_activation_description_title_3:
    "Los usuarios sin turnos no podrán acceder a Workspace",
  successful_activation_description_subtitle_3: `¿Olvidaste a alguien? Vaya a <span class="font-weight-bold"><a href="/users">Usuarios</a></span> para solucionar el problema.`,
  schedule_explanation:
    "Nuestro equipo está disponible para que usted responda todas sus preguntas. Programe una cita y hable con nuestro equipo de especialistas.",
  control_activation_schedule_title:
    "¿Tuviste alguna duda sobre activar Control?",
  illustration_by: "Ilustración de",
  illustrations_by: "Ilustracións por",
  seconds: "Segundos",
  second: "Segundo",
  tutorial_video: "Tutoriales en vídeo",
  and: "y",
  uppercase_send_email: "Enviar correo electrónico",
  lowercase_send_email: "enviar correo electrónico",
  successful_schedule_activation_title:
    "Activación programada del control de acceso para",
  successful_schedule_activation_description_title_1:
    "Activaremos el control automáticamente en la fecha y hora indicadas arriba",
  successful_schedule_activation_description_subtitle_1:
    "Puede cancelar la programación en cualquier momento",
  successful_schedule_activation_description_title_2:
    "Después de la activación, para acceder al Workspace, los usuarios deben seguir las reglas configuradas en sus respectivos turnos",
  successful_schedule_activation_description_title_3:
    "En caso de que los usuarios no hayan configurado una contraseña, en el próximo inicio de sesión después de la activación, los usuarios deben utilizar la contraseña temporal para acceder",
  successful_schedule_activation_description_title_4:
    "Los usuarios sin turno no podrán acceder al Workspace después de la fecha de activación",
  suspended_alert:
    "Estimado cliente, <br /> Tu acceso está temporalmente suspendido debido a un retraso en el procesamiento del pago de tu plan.",
  service_soon_available: "El servicio estará disponible en algunos días!",
  business_contact:
    "Nuestro equipo comercial ha sido notificado y se pondrá en contacto en breve.",

  // TODO: REMOVER AVISOS DO SSO ANTIGO APÓS 04/03/24
  old_sso_title_message:
    "Notificación Importante: ¡Hay una nueva versión de SSO disponible!",
  old_sso_title_message_2:
    "La versión actual del sistema de inicio de sesión único se desactivará el 04/03/2024 y la versión más reciente entrará en vigencia.",
  old_sso_sub_message:
    "Adelántese y configure la actualización de Conecta Control ahora para disfrutar de un sistema de inicio de sesión más eficiente y actualizado. Consulte la documentación y siga la guía paso a paso para una transición sin problemas.",
  permissions: "Permisos",

  single_access_alert:
    "La liberación de acceso único ahora se llama 'Acceso Temporal'.",
  single_access_alert_rules: "Algunas reglas han cambiado:",
  single_access_description:
    "El acceso temporal anula todas las reglas de turno, permitiendo al usuario acceder a su cuenta de correo electrónico.",
  single_access_description2:
    "Al definir un tiempo de acceso, el usuario puede acceder a su cuenta en cualquier momento dentro del período definido.",
  single_access_description3:
    "Si no se define un tiempo, el usuario perderá el acceso temporal tan pronto como cierre la sesión.",
  notification: "Notificación",
  grant_single_access_description:
    "Otorgando acceso temporal sin establecer un límite de tiempo. Al primer cierre de sesión, el acceso otorgado será revocado. Si se establece un límite de tiempo, el usuario puede iniciar sesión en cualquier momento dentro del período válido. <br> El tiempo de acceso comienza a partir del próximo inicio de sesión.",
  remove_single_access_description:
    "Está a punto de eliminar el inicio de sesión temporal para este usuario. No podrá iniciar sesión si está fuera del horario establecido.",
};

const feedback = {
  rating_error: "Seleccione un nivel de satisfacción para enviar la encuesta.",
  no_evaluation: "Sin evaluacion",
  very_unsatisfied: "Muy insatisfecho",
  unsatisfied: "Insatisfecho",
  indifferent: "Indiferente",
  satisfied: "Satisfecho",
  very_satisfied: "Muy satisfecho",
  evaluate_your_experience: "Califica tu experiencia en",
  review_sent: "Reseña enviada, gracias!",
  leave_a_comment: "Deja un comentario (Opcional)",
  successful_feedback_sent: "Todo listo, evaluación registrada!",
  edit_feedback: "Editar comentarios",
  feedback_error: "Error al registrar los comentarios, inténtalo de nuevo",
  rate_your_experience: "Califica tu experiencia",
  rate_your_experience_subtitle:
    "Comparte con nosotros tu experiencia usando esta función.",
  rate_app_on_marketplace: "Califica la aplicación en el mercado",
  common_did_not_work: "No funcionó",
  common_confusing_interface: "Interfaz confusa",
  common_slow_performance: "Rendimiento lento",
  common_options_missing: "Faltan más opciones",
  group_add_or_remove_users_incorrect: "Usuario/grupo incorrecto",
  group_add_or_remove_users_missing: "Usuario/grupo no encontrado",
  change_user_password_incorrect: "Contraseña incorrecta",
  change_user_password_missing: "Faltan más reglas",
  change_user_password_usability: "Reglas excesivas",
  customize_login_preview: "Previa de inicio de sesión diferente",
  control_activation_usability: "Usuarios no bloqueados",
  control_activation_frequent: "Activación inesperada",
  message_placeholder: "Porque...",
};

/* TELA DE LOGIN */
const login = {
  login: "Iniciar sesión con Google",
  msLogin: "Iniciar sesión con Microsoft",
  help: "Ayuda",
  login_description:
    "Esta aplicación solo está disponible para administradores corporativos de Google Workspace, por lo que debe iniciar sesión con su cuenta de administrador.",
  privacy: "Privacidad",
  terms: "Condiciones",
  init_session: "Haga el login para continuar",
  login_preview_info:
    "Esta es una vista previa de su página de inicio de sesión",
  agreement_label: "Al crear una cuenta, aceptas nuestra ",
  slogan: `<span class="login-text-spotlight">Controlar</span> <br /> el <span class="login-text-spotlight">uso</span> <br /> del <span class="login-text-spotlight">correo electrónico corporativo</span>`,
  scopes_help: "Comprender los alcances solicitados",
  try_for_free: "Pruébalo gratis",
  feature_list_label_1:
    "Restricción de acceso por hora, ubicación y dispositivo",
  feature_list_label_2: "Cierre de sesión de usuarios fuera de horario",
  feature_list_label_3: "¡es mucho más!",
  installApp: "Instala la aplicación en tu Google Workspace",
};

/* TELA DE AUTENTICAÇÃO */
const auth = {
  authenticating: "Autenticando",
  error_invalid_grant:
    "URL invalida. Verifique los permisos y vuelva a intentarlo.",
  error_missing_code:
    "Ocurrió un error al intentar obtener el token. Compruebe los permisos y vuelva a intentarlo.",
  error_no_domain_associated:
    "No hay ningún dominio asociado a esta cuenta. Inicie sesión con una cuenta asociada con Workspace.",
  error_unregistered_user: "Usuario no encontrado en nuestra base de datos.",
  error_marketplace_not_installed: `La aplicación no está instalada en su dominio. Instale la aplicación desde <a href="https://workspace.google.com/marketplace/app/control/${process.env.VUE_APP_MARKETPLACE_APP_ID}" target="_blank">Google Workspace Marketplace</a>.`,
  error_invalid_google_access_token: `Token de acceso no válido. Algún permiso de acceso está pendiente. Para iniciar sesión, debe permitir el acceso a todos los ámbitos en la <a href='https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}' target='_blank'>consola de administración de Google</a>.`,
  error_not_google_admin:
    "No eres administrador de Google Workspace o no tienes permiso para acceder a Conecta Control.",
  error_invalid_callback: "URL invalida. Inténtalo de nuevo.",
  error_forbidden_customer:
    "No es posible simular el inicio de sesión de este cliente. Compruebe los permisos de socios e inténtelo de nuevo.",
  error_invalid_token: "Token no válido, inicie sesión y vuelva a intentarlo.",
  error_login_required: "Inicie sesión y vuelva a intentarlo.",
  error_missing_scope:
    "Funcionalidades importantes pueden dejar de funcionar correctamente debido a ámbitos pendientes en la aplicación <strong>Conecta Control</strong>.",
  error_missing_scope_alert:
    "La resolución <strong>URGENTE</strong> de estos ámbitos es esencial para el FUNCIONAMIENTO PLENO del sistema.",
  error_missing_scope_support:
    "Si tiene alguna pregunta, comuníquese con nuestro equipo de soporte a través de los canales disponibles.",
  error_missing_scope_for_non_admin:
    "Contacte a su administrador de Google Workspace para solicitar la autorización de los alcances necesarios.",
  error_unknown: "Un error desconocido ocurrió. Por favor, intente nuevamente.",
  error_company_banned:
    "Acceso suspendido por criterio técnico o incumplimiento de las condiciones de uso.",
  marketplace_install:
    "Conecta Control no está instalado en tu Google Workspace, instálalo ahora para continuar.",
};

/* FORMULÁRIO DE ACESSO */
const accessForm = {
  confirm_title: "Demuestra que no eres un robot",
  phone_code_description: "Recibir un código de verificación en tu teléfono",
  phone_code_info:
    "Nosotros verificaremos este número mediante SMS (es posible que se apliquen cargos).",
  something_wrong: "Algo salió mal",
  validation_finished: "Validación completada",
  redirect_to_app: "Serás redirigido a la aplicación.",
  error_too_many_requests:
    "Por favor, espere un momento antes de realizar otra solicitud..",
  error_verify_code:
    "No ha sido posible enviar el código de verificación. Inténtalo de nuevo.",
  error_invalid_verification_code: "Código inválido.",
  error_code_expired: "Código expirado.",
  phone: "Teléfono",
  international_phone_hint:
    "Insertar un número de teléfono con el DDI, DDD y número",
  recaptcha_title: 'Resolver el "Captcha"',
  recaptcha_description:
    "Después de resolverlo, recibirá un SMS con un código de 6 dígitos para ingresar abajo",
};

/* PÁGINA INICIAL */
const home = {
  homepage: "Página de inicio",
  users_description: "Ver la lista de usuarios y asignar roles.",
  groups_description: "Crear, ver y editar la configuración del turno.",
  password_settings_description:
    "Solicitar cambios de contraseña y establecer la contraseña temporal.",
  integrations_description:
    "Configurar integraciones con VR Gente | PontoMais y Conecta Control AD.",
  customize_description:
    "Personaliza el logo de tu empresa en la página de inicio de sesión de Conecta Control",
  alerts_description:
    "Ver notificaciones sobre posibles errores e informes de inicio de sesión.",
  conecta_suite_description:
    "Obtenga acceso a nuevas configuraciones para conectar la administración con la tecnología.",
};

/* NAVBAR (BARRA SUPERIOR) */
const navbar = {
  global_search: "Buscar usuarios, turnos o configuraciones",
  privacy_policy: "Política de Privacidad",
  terms_of_use: "Condiciones del Uso",
  active_dark_theme: "Habilitar tema oscuro (BETA)",
  active_light_theme: "Habilitar tema claro",
  quick_settings: "Ajustes rápidos",
  support: "Soporte",
  update: "Actualizar",
  new_service_plan: "Contratar un plan o servicio",
  go_to_self_checkout: "Quiero saber los planes",
  sign_out: "Desconectar",
  //dislable control dialog
  disable_conecta_control_dialog_title: "Desactivar Conecta Control",
  disable_conecta_control_warning:
    "Este cambio afecta a todos los usuarios de tu empresa",
  disable_conecta_control_description:
    "Al desactivar Conecta Control, la autenticación del usuario vuelve a ocurrir con la contraseña de Google.",
  disable_conecta_control_description2:
    "Será necesario recuperar la contraseña para los usuarios que ya no dispongan de la contraseña de Google.",
  solve_problem: "resolver el problema: ",
  challenge_input_hint: "Ingrese el valor correcto y haga clic en verificar",
  challenge_input_hint_validation: "Introduzca el valor correcto",
  confirm_deactivate_message:
    "Para confirmar la desactivación del control de acceso con Conecta Control, solucione el problema y luego haga clic en verificar.",
  frequently_questions: "Preguntas frecuentes",
  chat_support: "Soporte vía chat",
  receive_support_label: "Habla con nuestros agentes a través del chat",
  need_help_question: "¿Necesitas ayuda?",
  need_help_label: `Seleccione el menú de soporte en la barra superior y luego 'Obtener soporte' para hablar directamente con nuestro equipo.`,
  send_feedback: "Enviar sugerencia",
  new: "Nuevo",
  schedule_meeting: "Programar reunión",
};

/* SIDEBAR (BARRA LATERAL) */
const sidebar = {
  home: "Inicio",
  users: "Usuarios",
  company_profile: "Perfil de la empresa",
  groups: "Turnos",
  password_settings: "Configuración de contraseña",
  integrations: "Integraciones",
  alerts: "Alertas",
  send_feedback: "Enviar sugerencia",
  button_feedback: "Envíe sus comentarios",
  customize_login: "Personalizar la pantalla de inicio de sesión",
};

/* PRODUTOS E ITENS QUE ENVOLVAM A CONECTA */
const conecta = {
  conecta_suite: "Conecta Suite",
  conecta_track: "Conecta Track",
  conecta_sign: "Conecta Sign",
  conecta_control: "Conecta Control",
  conecta_control_ad_sync: "Conecta Control AD",
  integracao_ponto_mais: "Conecta Control & VR Gente | PontoMais",
  another_products: "Otros productos Conecta",
  account_manager: "Administre su cuenta en Conecta Suite",
  visible_conecta_only: "Solo visible para los empleados de Conecta",
  conecta_partners_simulation: "Modo parceiro - Estás gerenciando ",
};

/* USUÁRIOS */
const users = {
  synchronizingUsers: "La sincronización de usuarios está en curso",
  syncUsers: "Sincronizar usuarios",
  system: "Sistema",
  delete_user: "Eliminó al usuario",
  update_user: "Actualizado el usuario",
  add_new_user: "Agregó el usuario",
  user_activate_sso: " activado el Conecta Control",
  user_disable_sso: " desactivado el Conecta Control",
  user_activate_sso_event: "Activado Control",
  user_disable_sso_event: "Desactivado Control",
  user_removed_group_rules: "Reglas del horarios eliminadas del grupo",
  user_added_group_rules: "Reglas del horarios agregadas al grupo",
  receive_user_single_access: "Recibió acceso temporal",
  removed_single_access_from: "Removió acceso temporal",
  user_deleted_groups: "Eliminó al grupo",
  user_updated_group_rules: "Reglas de grupo editadas",
  receive_single_access: "Recibió acceso temporal",
  removed_single_access: "Quitó acceso temporal",
  granted_single_access: "liberó acceso temporal",
  for: "por",
  to: "para",
  lost_user_single_access: "quitó acceso temporal de",
  grantedSingleAccess: "Acceso temporal concedido",
  active_users: "Usuarios activos",
  inactive_users: "Usuarios inactivos",
  participant_of: "Participante de",
  user: "Usuario",
  search_users: "Buscar usuarios",
  show_all_users: "Mostrando a todos los usuarios",
  users_selected: "usuarios seleccionados",
  last_login: "Último acceso",
  without_group: "Usuarios sin turno",
  is_on_vacation: "Usuarios de vacaciones",
  without_group_alert: "Alguns usuarios estão sin turno atribuído",
  without_group_alert2: "asígneles al menos un turno",
  confirm_delete_user_from_group_label:
    "¿Quieres confirmar la eliminación de este usuario del turno ",
  confirm_delete_users_from_group_label:
    "¿Desea confirmar la eliminación de los usuarios seleccionados del turno ",
  you_are_editing_this_user_group_membership:
    "Estás editando la pertenencia a un turno de este usuario.",
  manage_user_shifts_label:
    "Administrar los turnos en los que participa este usuario.",
  vacation_period: "Vacaciones",
  on_vacation: "Está de vacaciones",
  vacation_title: "Configurar modo vacaciones",
  vacation_subtitle:
    "Seleccione el período de vacaciones para el bloqueo de la sesión",
  vacation_description:
    "El modo de vacaciones impide que los usuarios accedan a sus cuentas durante un cierto período de tiempo.",
  set_user_vacation: "Asignar modo vacaciones",
  edit_vacation_period: "Editar período de vacaciones",
  scheduled_vacation: "Vacaciones programadas",
  remove_user_vacation: "Eliminar modo vacaciones",
  set_users_vacation: "Asignar modo de vacaciones a los usuarios",
  deny_user_access_in_vacation:
    "Bloquear el acceso de los usuarios durante las vacaciones",
  deny_user_access_in_vacation_description:
    "Elija qué usuarios desea bloquear durante el período de vacaciones y aplique el bloqueo de acceso al espacio de trabajo para períodos actuales o futuros",
  remove_users_vacation: "Eliminar el modo de vacaciones de los usuarios",
  request_single_access_description:
    "Ingrese su dirección de correo electrónico y describa a los adminsitradores el motivo de la solicitud.",
  request_password_change_description:
    "Ingrese su dirección de correo electrónico y comenta sobre su solicitud de cambio de contraseña con sus administradores.",
  login_history: "historial de inicio de sesión",
  history: "historial",
  title_deny_browser_access_in_conecta_control:
    "¡Elige cualquier navegador al que quieras autorizar el acceso de una forma fácil y sencilla!",
  description_deny_browser_access_in_conecta_control:
    "¡Seleccione un turno específico de su elección y agregue los navegadores a los que desea autorizar el acceso! ¡No puede ser más simple que eso!",
  changed_the_password_of: "cambió la contraseña de",
  change_own_password: "cambió su propia contraseña",
  apply_vacation_message_title:
    "Aplique un mensaje de vacaciones a sus empleados.",
  apply_vacation_message_subtitle:
    "Configure una respuesta automática de vacaciones para notificar que el usuario no podrá responder a los correos electrónicos recibidos durante el período de receso determinado en la aplicación.",
  apply_vacation_question: "¿Quieres aplicar el mensaje de vacaciones?",
  go_to_sign: "Sí, ve a Conecta Sign",
  min_len_is_10: "Deve contener al menos 10 caracteres",
  user_on_vacation_in_pontomais:
    "El usuario está de vacaciones en VR Gente | PontoMais",
  all_users_selected: "Todos los usuarios de la tabla han sido seleccionados",
  create_users: "Crear usuarios",
  control_only_password:
    "Esa contraseña solo funciona para hacer el login en Control",
  current_session_state: "Estado de la sesión actual",
  org_units: "Unidades organizativas",
  hasNoValuesToShow: "No hay valores para mostrar",
  customizeColumns: "Personalizar columna",
  moveColumnUp: "Mover columna hacia arriba",
  moveColumnDown: "Mover columna hacia abajo",
  user_added_manager_permission: "Administrador agregado",
  user_removed_manager_permission: "Aministrador eliminado",
  has_been_added_admin: "ha sido agregado como administrador",
  is_no_longer_an_administrator: "ya no es administrador",
};

const groups = {
  search_group: "Buscar turno",
  show_all_groups: "Mostrando a todos los turnos",
  show_all_participants: "Mostrando todos los participantes",
  participants_selected: "participants seleccionados",
  add_group: "Añadir turno",
  add_participants: "Añadir participantes",
  add_participants_label:
    "Agregue nuevos participantes para este turno ingresando sus direcciones de correo electrónico.",
  add_all_users_label:
    "Puede agregar todos los usuarios de la organización a la vez este turno.",
  add_all_users_confirm_action: "Agregar todos los usuarios actuales para al",
  edit_participants: "Editar participantes",
  groups_selected: "turnos seleccionados",
  manage_participants: "Gerenciar participantes",
  edit_password_policy: "Editar política de contraseñas",
  edit_operation_hours: "Configurar horarios de operación",
  edit_login_logout_access: "Configurar inicio y cierre de sesión",
  pontomais_login_rules: "Reglas de integración con VR Gente | PontoMais",
  login_rules: "Regla de inicio de sesión",
  logout_rules: "Regla de terminación de sesión",
  clocking_session_control_title: "Configuración de proveedor de checador",
  clock_selector_title: "Elige tu proveedor de checador",
  clock_provider_label: "Proveedor de checador",
  pontomais_login_message: "Permitir login solo después de checar",
  pontomais_auto_logout_info:
    "El cierre de sesión puede demorar hasta 5 minutos después de checar",
  pontomais_auto_logout_label:
    "Desconectar a los usuarios de todos los dispositivos después de checar...",
  pontomais_end_auto_logout_label: "Después de checar para salir",
  pontomais_break_auto_logout_label: "Después de checar para hacer una pausa",
  pontomais_login_description:
    "Establecer si los usuarios solo pueden iniciar sesión después de checar",
  pontomais_auto_logout_message:
    "Cierre la sesión de los usuarios de todos los dispositivos conectados después de checar para salir o hacer una pausa",
  pontomais_end_auto_logout_description:
    "El colaborador será desconectado después de checar al final de la jornada laboral",
  pontomais_break_auto_logout_description:
    "Se cerrará la sesión después de registrar el punto de salida del rango",
  pontomais_link_how_to_config_shift:
    "Como configurar los turnos en la VR Gente | PontoMais",
  advanced_logout_rule:
    "Desconectar a los usuarios de todos los dispositivos fuera del horario de atención",
  logout_rule: "Cerrar la sesión de los usuarios fuera del horario de atención",
  next_group_password_change: "Próximo cambio de contraseña el ",
  there_are_no_settings: "No hay ajustes",
  always_active: "Siempre activo",
  always_inactive: "Siempre inactivo",
  scheduled_activation: "Programado para activación.",
  group_info: "Información del turno",
  delete_group_title: "Eliminación de turnos",
  delete_group_label:
    "Se eliminará toda la información del turno, incluidas las reglas de acceso. Las cuentas de los participantes no se eliminarán.",
  activate_group_label:
    "Activa el turno ahora para comenzar a restringir a tus usuarios según las reglas definidas.",
  disabled_group_label:
    "Esta acción deshabilitará el turno, y tus usuarios ya no podrán acceder siguiendo la regla del turno. Deshabilitar solo si está seguro de que los usuarios ya están en otro turno.",
  delete_group_alert:
    "<b>¡Atención!</b> Los usuarios que están participando en el turno ya no estarán sujetos a sus reglas. <br> <strong>Sugerimos reasignar a los usuarios a otro turno.</strong>",
  clone_group_label: `<p>Esta acción clonará el turno y su información.</p>
    <p>Todas las configuraciones para ese turno se clonarán en un nuevo turno, como usuarios, accesos y horarios.</p>`,
  logout_group:
    "Se cerrará la sesión de los participantes en este turno, ¿quieres continuar?",
  the_group: "El turno",
  the_groups: "Los turnos",
  has_been_deleted: "ha sido eliminado",
  groups_have_been_deleted: "han sido eliminados",
  cannot_be_deleted: "no se puede eliminar",
  groups_cannot_be_deleted: "no puedem ser eliminados",
  delete_selected_shifts: "Eliminar los turnos seleccionados",
  cant_delete_groups: "Los turnos deben estar deshabilitaados",
  cant_activate_groups: "Solo se seleccionaron los turnos habilitados",
  cant_deactivate_groups: "Solo se seleccionaron turnos deshabilitados",
  was_cloned: "ha sido clonado",
  could_not_be_cloned: "no se puede clonar",
  updated_password_at: "Último cambio de contraseña",
  automatic_logout: "Cierre de sesión automático",
  next_password: "Próximo cambio de contraseña",
  days_to_change_password: ["Cambio cada", "días"],
  login_shifts: "Acceso",
  login_label:
    "Valida si el usuario está en horario laboral mediante software de control horario.",
  session_termination: "Terminación de sesión",
  session_termination_label: `Los participantes del turno se desconectan al final de una "regla de acceso" (período) del turno.`,
  integration: "Punto de control",
  password_policy_info_shifts:
    "Define en qué intervalo de tiempo los usuarios deben cambiar sus contraseñas personales.",
  groups_disabled:
    "Los turnos desactivados no aparecen en los horarios de operación",
  /* Regras */
  rule: "Regla",
  access_rule: "Tiempo de acceso",
  add_rules: "Agregar reglas",
  add_new_rule: "Añadir nueva regla",
  remove_rule: "Eliminar regla",
  edit_rule: "Editar regla",
  update_selected_rules: "Editar reglas seleccionadas",
  remove_selected_rules: "Eliminar reglas seleccionadas",
  showing_all_rules: "Mostrando todas las reglas",
  selected_rule: "regla seleccionada",
  selected_rules: "reglas seleccionadas",
  /*  */
  login_logout_access: "Inicio y cierre de sesión",
  time_settings: "Horarios de operación",
  auto_logout_label:
    "Cierra la sesión de los usuarios del navegador al final del tiempo",
  advanced_logout_label:
    "Cierre la sesión de los usuarios al final del horario de todos los dispositivos conectados",
  add_group_dialog_description:
    "¿Está seguro de que desea agregar usuarios a este turno?",
  remove_group_dialog_description:
    "¿Está seguro de que desea eliminar usuarios de este turno?",
  /* Ações */
  logout_groups_success: "¡Los turnos cerraron sesión correctamente!",
  logout_group_success:
    "¡Los participantes de este turno han sido desconectados con éxito!",
  logout_groups_failure: "No se puede cerrar sesión en estos turnos.",
  logout_group_failure:
    "No fue posible cerrar la sesión de los participantes de este turno.",
  force_change_pass_groups_success:
    "¡Solicitud de cambio de contraseña exitosa!",
  force_change_pass_groups_failure:
    "¡No se puede solicitar el cambio de contraseña!",
  participants_info:
    "Administre los miembros del turno agregando o excluyendo usuarios.",
  password_policy_info:
    "Agregar una política de contraseñas para este turno, por lo que los usuarios deberán cambiar su contraseña periódicamente.",
  disable_group_info: "Desactiva y activa el turno cuando quieras.",
  logout_settings_info:
    "Defina cómo el usuario puede iniciar la sesión y cómo se hará el final.",
  clone: "Clonar",
  add_users_to_group: "Usuarios agregados al turno",
  remove_users_from_group: "Usuarios eliminados del turno",
  add_groups_to_user: "Turnos agregados al usuario",
  remove_groups_from_user: "Turnos eliminados del usuario",
  user_added_on_shifts: "fue agregado a los turnos",
  user_removed_from_shifts: "fue retirado de los turnos",
  add_vacation_to_user: "Aplicó período de vacaciones al usuario:",
  remove_vacation_from_user: "Período de vacaciones eliminado del usuario:",
  group_config: "Configuración de turno",
  general_config: "Configuración general",
  participants: "Participantes",
  passwords: "Contraseñas",
  general_config_description:
    "Administre el nombre, el estado del turno, el período de activación, etc",
  participants_description:
    "Configurar los usuarios que deberían verse afectados por las reglas de este turno",
  passwords_description:
    "Administre reglas periódicas de cambio de contraseña o fuerce el cambio de contraseña inmediato",
  login_logout_access_description:
    "Definir procesos que afectarán el inicio/cierre de sesión del usuario",
  time_settings_description:
    "Configurar los horarios y días disponibles para que el usuario inicie sesión",
  clocking_login_config: "Configuración de inicio de sesión via checador",
  logout_config: "Configuración de cierre de sesión",
  clocking_logout_config: "Configuración de cierre de sesión via checador",

  //mudar isso quando ip e datas especiais forem separados
  ip_address_info:
    "La restricción por IP le permite permitir el acceso por ubicación a través de direcciones IP y DNS.",
  time_settings_info:
    "Para ingresar un período de permiso, haga clic en el calendario, para excluir un período, haga clic en “Horario de funcionamiento“ para ir a la configuración.",
  request_group_password_change:
    "¿Está seguro que desea solicitar un cambio de contraseña para los participantes de este turno?",
  restriction_label_by_browser: "Bloqueo del navegador",
  allowed_browsers_info:
    "La restricción del navegador le permite otorgar acceso solo a navegadores seleccionados.",
  allowed_os_info:
    "La restricción por sistema operativo le permite otorgar acceso solo a sistemas seleccionados.",
  allowed_browsers: "Navegadores permitidos",
  allowed_os: "Sistemas operativos permitidos",
  restrict_operational_system: "Restringir el sistema operativo",
  os: "sistema operativo",
  activation_period: "Período de activación del turno",
  activation_period_description:
    "Elige el intervalo de tiempo que el turno permanecerá activo",
  activation_period_placeholder: "Periodo que permanecerá activo el turno",
  optional: "opcional",
  disable_shift_message_in_period: `<strong>ADVERTENCIA:</strong> este turno tiene un periodo de activación definido. Si se confirma, esta configuración será eliminada.`,
  edit_activation_period: "Editar período de activación",
  remove_activation_period: "Eliminar período de activación",
  activate_shift_message_in_period: `Este turno tiene un período de activación definido, luego de la confirmación, esta configuración será eliminada.`,
  activate_shift: "Activar turno",
  activation_period_label: "Seleccione el período de activación del turno",
  activate_shifts_successfully:
    "Los turnos seleccionados se han activado con éxito",
  deactivate_shifts_successfully:
    "Los turnos seleccionados han sido desactivar con éxito",
  deactivate_shifts_error:
    "Hubo un error al desactivar los turnos, intente nuevamente",
  activate_shifts_error:
    "Hubo un error al activar los turnos, intente nuevamente",
  deactivate_selected_shifts: "Desactivar turnos seleccionados",
  cancel_schedule_activation: "Cancelar programación",
};

const adminPermissions = {
  user_permissions: "Permisos de usuario",
  manage_permissions: "Administrar permisos",
  add_admin: "Agregar administrador",
  remove_admin: "Eliminar administrador",
  add_admin_main_text:
    "Seleccione un usuario para agregar permisos de administrador en Conecta Control.",
  add_admin_helper_text:
    "Esta configuración no interfiere con los permisos de la consola de administración de Google.",
  remove_admin_main_text:
    "Para eliminar el permiso de administrador de Conecta Control de este usuario, haga clic en el botón confirmar.",
  admin_permissions_description:
    "Gerencie os administradores do painel Conecta Control",
};

/* TOUR */
const tour = {
  buttonSkip: "Saltar tutorial",
  startTutorial: "Iniciar tutorial por herramienta",
  buttonPrevious: "Anterior",
  buttonNext: "Siguiente",
  buttonStop: "Concluir",
  getting_start: "Comenzando",

  user_tour: "Administrar usuarios",
  user_tour_description:
    "Ver usuarios del espacio de trabajo, sus rutinas y cambiar contraseñas.",
  company_profile_tour: "actualización del perfil",
  company_profile_tour_description:
    "Visite Connect Suite y actualice los datos del perfil de su empresa.",
  permissions_tour: "Permisos de usuario",
  permissions_tour_description:
    "Gestiona y asigna permisos para que otros usuarios gestionen el panel de Conecta Control. Los permisos son únicos para este panel.",
  group_tour: "Configurar un turno",
  group_tour_description:
    "Con los turnos, puede crear reglas de acceso personalizadas por fecha, hora y ubicación.",
  password_tour: "Establecer una contraseña temporal",
  password_tour_decription:
    "Establezca una contraseña temporal para el primer inicio de sesión de los usuarios o para restablecer la contraseña del usuario.",
  integrations_tour: "Configurar integraciones",
  integrations_tour_description:
    "Combine Conecta Control con hasta dos herramientas más potentes y mejore el control de acceso.",
  suite_tour: "Conecta Suite",
  suite_tour_description:
    "Acceda al panel de Conecta Suite, simplifique la gestión de Google Workspace con configuraciones avanzadas para usuarios, aplicación masiva de firmas de correo electrónico y mensajes de vacaciones.",
  config_tour: "Habilite el control de acceso para todos",
  config_tour_description:
    "Configura, personaliza y activa o desactiva Conecta Control como gestor de inicio de sesión de tus usuarios. ¡Mantenga la calma! Tras su configuración, los usuarios permanecerán logueados hasta la hora de cierre de sesión establecida por el turno al que pertenecen.",
  activate_control_tour: "Activación de Conecta Control",
  activate_control_tour_description:
    "Después de la configuración, active Conecta Control. Los usuarios permanecerán conectados hasta la hora de cierre de sesión establecida por el turno al que pertenecen.",

  start_settings: "Iniciar configuración",
  start_settings_after: "No configurar por ahora",
  firstStepTitle: "Control de acceso al espacio de trabajo de Google",
  firstStepDescription:
    "¡Bienvenido, estamos felices de tenerte como nuestro nuevo usuario! Para comenzar a utilizar todos los beneficios de Conecta Control, debes dar algunos pasos para finalmente activar tu producto",
  secondStepTitle: "Definición de turnos",
  secondStepDescription:
    "Decida <b> cuándo y dónde </b> sus usuarios pueden acceder a Google Workspace creando un turno con reglas personalizadas",
  thirdStepTitle: "Agregar usuarios a turnos",
  thirdStepDescription:
    "Incluir usuarios como miembros de un turno para que sigan reglas preestablecidas",

  fourthStepTitle: "Activar Connect Control",
  fourthStepDescription:
    "Al final del proceso, todo lo que tienes que hacer es activar la aplicación para que las reglas aplicadas comiencen a aplicarse a los usuarios. ¿Empecemos con la instalación?",
  schedule_activation_logout_alert:
    "Los usuarios no cerrarán sesión automáticamente después de la activación automática",
};

const welcome = {
  title: "¡Bienvenido(a)!",
  title_login_preview: "Bienvenido",
  subtitle:
    "En unos instantes tus usuarios estarán sincronizados.<br>Consulta lo que necesitas saber para iniciarte en Conecta Control:",
  step1title: "Controlar el acceso a Google Workspace",
  step1description:
    "Conecta Control es como una capa de seguridad para acceder a los productos de Google Workspace.",
  step2title: "Restricciones de acceso con turnos",
  step2description:
    "Crea turnos y define quién, cuándo y dónde acceder a las herramientas de Google Workspace.",
  step3title: "¡Quédate tranquilo!",
  step3description:
    "El control de acceso de Conecta Control solo tendrá efecto después de su configuración, te guiaremos en este proceso.",
};

const activationSteps = {
  /* GET STARTED */

  getStartedTitle: "Bienvenido a activar Conecta Control",

  beforeStartActivationProccess: "Antes de começar, você precisa saber",

  getStartedSteps: "Este é um processo de 3 etapas",

  getStartedActivationProccess:
    "Você pode pausar este processo a qualquer momento",

  getStartedProgress:
    "Seu progresso não será perdido se você sair desta página",

  getStartedLogoutUsersTip:
    "Ao final do processo, você decidirá se os usuários permanecerão logados ou terão que logar novamente",

  letsStart: "Vamos a empezar",

  skipStep: "Saltar paso",

  whatDoYouWantToDo: "¿Qué quieres hacer ahora?",

  redirectTitle: "Serás redirigido a",

  dontWorryYourActivationProgressIsSaved:
    "No te preocupes, tu progreso hasta ahora no se perderá",

  comeBackAnytimeByClickingInControlActivation: `Vuelve en cualquier momento haciendo clic en "Activar Connect Control"`,

  /* PROGRESS */

  activationStepOneTitle: "Acceso de usuario",
  activationStepTwoTitle: "Contraseña temporal",
  activationStepThreeTitle: "Configurar y activar",

  /* STEP 1 */

  activaciónSessionOneTitle: "Establecer acceso de usuario",

  userShiftSettings: "Configurar turnos de usuarios",

  shiftTip:
    "Con turnos, <b>define reglas de acceso</b> que tus <b>participantes deben seguir para acceder</b> al Espacio de Trabajo",

  shiftMembersTip:
    "<b>Agregar usuarios a turnos</b> para que puedan <b>seguir</b> esas <b>reglas</b>",

  nonShiftMembersTip:
    "<b>Los usuarios sin un turno definido</b> <b>no podrán iniciar sesión</b> después de la activación",

  shiftChanges:
    "<b>Ajusta</b> turnos, usuarios participantes, reglas de acceso y cierra sesión <b>cuando quieras</b>, <b>sin</b> tener que <b>reactivar</b>.",

  userOptionRedirectToShifts: "Quiero crear/editar mis propios turnos",

  userOptionRedirectToUsers: "Quiero agregar usuarios a turnos existentes",

  /* STEP 2 */

  activationSessionTwoTitle: "Establecer contraseña temporal",

  whatDoYouWantToDoWithUsersFirstAccess:
    "¿Cómo desea configurar la contraseña de sus usuarios?",

  defineTemporaryPassword: "Establecer contraseña temporal",

  temporaryPasswordWarningCoWorkers:
    "Advertir a los usuarios sobre el <b>primer acceso con contraseña temporal</b>.",

  temporaryPasswordUserChangePassword:
    "Los usuarios nuevos en Control <b>podrán elegir una nueva contraseña</b> en su primer inicio de sesión.",

  temporaryPasswordFutureChanges: `La <b>contraseña</b> <b>temporal se puede cambiar o consultar</b> en cualquier momento en el menú <b>"Configuración de contraseña"</b>.`,

  tipToAdminAboutThePasswords:
    "Las <b>contraseñas de generación automática se envían por correo electrónico en este momento.</b> Los usuarios pueden cambiarlas después de iniciar sesión.",

  tipToAdminAboutChangePasswordLink:
    "Los <b>enlaces se están enviando por correo electrónico en este momento.</b> Los usuarios podrán cambiar su contraseña a través del enlace.",

  temporaryPasswordChanged: "Contraseña temporal configurada",

  sendEmailToChangePassword: "Enviar correo para cambio de contraseña",

  informUsersAboutTheNewPassword:
    "Informar a los empleados de la siguiente contraseña:",

  informUsersAboutRandomPassword:
    "La contraseña temporal generada automáticamente se enviará individualmente al correo electrónico principal de cada usuario.",

  informUsersAboutEmailToChangePassword:
    "Los correos se enviarán individualmente al correo principal de cada usuario.",

  informAdminAboutTheRandomPasswords:
    "No te preocupes, <b>las contraseñas de generación automática se enviarán por correo electrónico</b> después de <b>concluir este paso</b>.",

  informAdminAboutChangedPassword:
    "Al abrir el enlace, <b>los usuarios deberán iniciar sesión con Google</b> para poder acceder al <b>cambio de contraseña</b>.",

  informAdminAboutActivationWithEmail:
    "<b>Se recomienda programar la activación o esperar</b> hasta que todos los usuarios hayan cambiado sus contraseñas.",

  whatDoYouWantToDoWithLoggedUsers:
    "¿Qué quieres hacer con los usuarios que actualmente están conectados?",

  activationUsersLogoutHelp: `Si lo prefieres, <b>puedes cerrar sesión en cualquier momento</b> a través de la página "Usuarios" o cerrar sesión por turno en la página "Turnos"`,

  redirectToForceLogoutTip:
    "Después de la activación, seleccione los usuarios que deben cerrar sesión",

  forceLogoutToAllUsersTip:
    "Se cerrará la sesión de todos los usuarios actualmente conectados",

  dontForceLogoutTip:
    "A los usuarios que actualmente están conectados no se les cancelará la sesión",

  setUsersLogout: " Establecer cierre de sesión de usuarios",

  activatingControlAccess: "Activando control de acceso..",
  activatingControlAccessTip:
    "Mientras configura Control, notifique a los colaboradores sobre el primer acceso con la siguiente contraseña:",

  control_activation: "activación del Conecta Control",
  stepSectionTitle: "Configuramos Conecta Control",
  stepSectionSubtitle: "En unos pasos más activarás el control de acceso",
  activationStep1Description: `<p>En primer lugar, es necesario configurar y organizar los turnos con los usuarios y los horarios en los que pueden acceder a su cuenta de Google, si aún no lo ha hecho, haga clic en "Configurar"</p>
    <p>¡Recordar que este paso es fundamental para que la herramienta funcione!</p>
    <p>Para tener acceso a los productos de Google Workspace, el usuario debe ser parte de un turno. Continúe solo si desea restringir completamente el acceso de estos usuarios.</p>
    <p>Ahora estamos listos para proceder con la activación de Conecta Control.</p>
    <p>El siguiente paso es <b>establecer la contraseña predeterminada</b> para la herramienta, ¿de acuerdo?</p>`,
  activationStep2Description: `<p>Llegaste aquí, eso es genial, ahora vamos a configurar la contraseña predeterminada, te prometo que será rápido.</p>
    <p>La contraseña predeterminada es <b>muy importante a la hora de activar y utilizar Conecta Control</b>, pero ¿por qué?</p>
    <p>Esta será la primera contraseña con la que su colaborador accederá a Conecta Control, luego de este primer acceso, el colaborador creará una nueva contraseña de acceso.</p>
    <p>Ahora que ha creado la contraseña predeterminada, el siguiente paso es informar a los colaboradores sobre este cambio, ¿de acuerdo?</p>`,
  activationStep3Title: "Aviso a los colaboradores",
  activationStep3Description: `<p>¡Sí, esa parte también es muy importante! Estamos casi listos para finalizar el proceso de activación de Conecta Control<p>
    <p>Todo lo que queda por hacer es notificar a los colaboradores sobre este turno.</p>
    <p>Como puedes ver ya estamos dejando el mensaje listo para este aviso, solo necesitas copiarlo y enviarlo por correo electrónico.</p>
    <p>Fácil verdad, después de enviar el mensaje espera de 1 a 3 días para que todos hayan mirado el mensaje, también te recomendamos que envíes este aviso por WhatsApp :)</p>
    `,
  warning_control_activation_message_first: `<p>Estimado colaborador,</p>
    <p>Después del día <span style="color:red">[inserte el día aquí]</span> cuando se le solicite el nombre de usuario y la contraseña en Google Workspace (G Suite), accederá con la siguiente contraseña temporal: </p>`,
  warning_control_activation_message_second: `<p><b>Advertencia:</b> Anótelo en algún lugar para que no lo olvide, porque después del <span style="color:red">[día de activación]</span> no poder acceder al correo electrónico con su contraseña actual!
    <p>Después del primer inicio de sesión con la contraseña temporal, deberá crear una nueva contraseña personalizada. Puede que sea el mismo al que estás acostumbrado.</p>
    <p>Después, podrá utilizar las herramientas de Google Workspace (G Suite) con normalidad.</p>
    <p>¡Cualquier pregunta, siéntete libre!</p>
    <p>Abrazos,<p>
    <p><span style="color:red">[su nombre]</span></p>`,
  uncompleted_steps: `<p>Parece que algunos pasos no se han completado y pueden afectar la activación de Conecta Control. ¿Quieres continuar de todos modos?</p>
    <p>Para completar un paso, haga clic en el número que lo representa.</p>`,
  go_to_users: "Ir a usuarios",
  go_to_groups: "Ir a turnos",
  notify_users_via_email: "Notificar a los usuarios por correo electrónico",
  notify_users_via_email_description:
    "Todos los usuarios de la empresa recibirán un correo electrónico con instrucciones sobre el primer acceso utilizando la contraseña temporal proporcionada. ¿Desea continuar?",
  generateRandomPasswords: "Generar contraseñas temporales",
  generate_link_to_change_passwords:
    "Enviar un enlace para cambiar la contraseña",
  tipAboutExclusivePassword:
    "Se creará una contraseña exclusiva y se enviará al correo electrónico principal de cada usuario.",
  tip_about_send_link_to_chenge_password:
    "Se enviará un enlace exclusivo de cambio de contraseña de Conecta Control a cada usuario por correo electrónico.",
  schedule_activation_description:
    "Programe la activación automática del Conecta Control eligiendo la fecha y hora deseadas",
  activation_date_hint: "Elija una fecha entre hoy y los próximos 14 días",
  activation_date_title: "Programar activación del Conecta Control",
  schedule_activation: "Programar activación",
  activate_later: "Activar más tarde",
  activate_later_description: "Salir y activar manualmente más tarde",
  activation_domain: "Dominio de activación",
  activation_domain_description:
    "Selecciona el dominio que deseas activar Conecta Control",
  selectionDomainInfo:
    "<strong>Todos los</strong> <strong>usuarios y administradores</strong> del dominio seleccionado pasarán por las reglas de Conecta Control",
};

const activationStepsMs = {
  ms_ways_to_activate_control:
    'Es posible activar el Control para la Microsoft de dos maneras: por el "Powershell" o por el "Graph Explorer".',
  ms_with_powershell:
    'La activación en una terminal "Powershell" es hecha ejecutando los seguientes comandos:',
  ms_install_module: 'Instalación del módulo "Microsoft.Graph"',
  ms_module_authorize: "Conectar para autorizar",
  ms_control_activate: "Activación del Control",
  ms_select_domain:
    "La activación es hecha en un único dominio de su empresa. Seleccione el dominio para actualizar el código.",
  ms_domain_already_federated:
    'En caso algún dominio ya estea como "Federated", es necesario quitar la federación. Seleccione el dominio y utlize el comando abajo:',
  ms_command_to_deactivate:
    "Ese es el mismo comando para desactivar el Control para Microsoft.",
  ms_with_graph_explorer:
    'La activación en "Graph Explorer" es hecha con una solicitud con las informaciones proporcionadas abajo y utilizando la herramienta del link ',
  ms_rest_api_activate_headers_key:
    "Agregue como encabezado de las solicitudes la clave ",
  ms_rest_api_activate_headers_value: "y el valor ",
  ms_rest_api_scopes_required:
    "Se requiere dar permiso a los siguientes escopos:",
  ms_rest_api_activate:
    'Seleccione el método de solicitud "POST" y ingrese la seguiente dirección de solicitud (API):',
  ms_rest_api_activate_body:
    "Copie y pegue el cuerpo de la solicitud (body) y ejecute la solicitud",
  ms_rest_api_deactivate:
    'Para desactivar, cambie el método de la solicitud para "PATCH" y la dirección de la solicitud (API) a la seguiente ',
  ms_rest_api_deactivate_body:
    "Cambie também el cuerpo de la solicitud (body) a ",
  domain: "Dominio",
  select_a_domain: "Seleccione un dominio",
  usersNotFoundOnSelectedDomain:
    "No se encontraron usuarios en el dominio seleccionado",
};

/* TABLE HEADERS EM GERAL */
const headers = {
  name: "Nombre",
  email: "Correo electrónico",
  date: "Fecha de Registro",
  hour: "Hora",
  ip: "Dirección IP",
  last_login: "Último acceso",
  permissions: "Permisos",
  admin: "Administrador",
  member: "Miembro",
  group: "Turno",
  username: "Nombre de usuario",
  status: "Situación",
  active: "Activo",
  not_syncronized: "No sincronizado",
  actives: "Activos",
  disabled: "Inactivo",
  author: "Autor",
};

const customizeLogin = {
  uplaod_img_label: "Sube el logo de tu empresa",
  pers_login_title: "Personalice la página de inicio de sesión de su empresa",
  pers_login_subtitle:
    "Cambie la configuración de su página de inicio de sesión y visualice en tiempo real",
  change_existing_infos: "Cambiar informaciones existentes",
  primary_color: "Color del botón primario",
  secondary_color: "Color del botón secundario",
  text_color: "Color de los textos",
  remove_company_logo: "Quitar el logo de la empresa",
  remove_background_image: "Quitar el imagen de fondo",
  remove_logo_error: "Error al eliminar el logotipo de la empresa",
  remove_image_error: "Error al eliminar la imagen de fondo",
  crop: "Cortar",
  cut_out: "Separar",
  crop_image: "Delimitar imagen",
  remove_image: "Eliminar Imagen",
  undo_logo_changes: "Deshacer cambios",
  remove_current_logo: "Excluir logo atual",
  remove_current_background_image: "Excluir imagen atual",
  logo_height_placeholder: "Ingrese la altura en píxeles",
  logo_width_placeholder: "Introduzca el ancho en píxeles",
  reset_image_dimensions: "Restablecer dimensiones de la imagen",
  image_size_error: "¡El tamaño de la imagen debe ser inferior a 2 MB!",
  full_screen: "Pantalla completa",
  view_in_full_screen: "Ver en pantalla completa",
  background_image: "Imagen de fondo",
  background_image_description:
    "Utiliza una imagen personalizada en tu pantalla de inicio de sesión.",
  recomendaciones: "Recomendaciones:",
  image_color_recomendation:
    "Utilizar una imagen clara (preferencia por color blanco).",
  image_resolution_recomendation:
    "La resolución de la imagen debe ser alta (full hd), para no haber distorsiones.",
  more_info: "Más informaciones",
  powered_by_suite: "Esta pagina esta siendo gerenciada por Conecta Suite.",
  logo_width: "Ancho del logotipo",
  logo_height: "Altura del logotipo",
  logo: "Logo",
};

/* LABELS */
const labels = {
  greater_than_rule: "El valor no puede ser mayor que ",
  less_than_rule: "El valor no puede ser menor que ",
  access_time: "Tiempo de acceso",
  time_unit: "Unidad de medida",
  time_unit_checkbox_text:
    "Establecer el tiempo de acceso después de iniciar sesión",
  time_unit_checkbox_help_text:
    "Marque esta opción para establecer el tiempo que el usuario permanecerá conectado después de iniciar sesión",
  automatic: "Automático",
  company_logo: "Logo de la compañía",
  screen_colors: "Colores de pantalla",
  logo_description:
    "Cargue una imagen y muestre su identidad de marca a los usuarios.",
  screen_color_description:
    "Personalice los colores de la pantalla de inicio de sesión.",
  height: "Altura",
  width: "Ancho",
  upload_image_title: "Agregar una imagen en formatos .jpg, .jpge, .png, etc",
  logo_image: "Logotipo",
  upload_image: "Cargar imagen",
  description_height_error: "Introduzca una altura entre 40 y 500.",
  description_width_error: "Introduzca un ancho entre 40 y 500.",
  password: "Contraseña",
  access: "Acceso",
  type_password: "Escribe tu contraseña",
  type_email: "Escriba su correo electrónico",
  restore_default: "Restaurar predeterminado",
  leave_without_save_changes:
    "Algunos cambios no se guardaron. ¿Quieres salir sin guardar?",
  are_you_sure: "¿Estás seguro de que quieres ",
  confirm_this_action: "confirmar esta acción",
  sync: "Sincronizar",
  advanced: "Avanzado",
  note: "Nota: ",
  disabled_log: "DESACTIVADO",
  active_log: "ACTIVADO",
  type_paste_or_search: "Escriba, pegue o busque",
  next_logout: "Siguiente cierre de sesión establecido en",
  to_cancel_this_edit: 'Para cancelar la edición, haga clic en "Cancelar".',
  selected_filters: "Filtros seleccionados: ",
  choose: "Elegir",
  company_profile_label: "Actualizar información de la empresa y facturación",
  view_more: "Ver más",
  password_strength: "Seguridad de la contraseña",
  password_min_char: "La contraseña no tiene el número mínimo de caracteres.",
  show_users_without_group: "Mostrar usuarios sin turno",
  is_google_admin_label:
    "Indica si el usuario tiene o no permiso de administrador en el panel de control de Google Workspace. Esta configuración solo se puede realizar directamente desde el panel de control de Google.",
  is_active_user_label:
    "Indica que el administrador suspendió la cuenta de este usuario en el panel de control de Google Workspace.",
  not_syncronized_ms_label:
    "Indica que el usuario aún no ha sido creado en Microsoft.",
  suspended: "Suspendido",
  function: "Ocupación",
  all_of: "Todos los ",
  page_selected: " nesta página estão seleccionados. ",
  table_selected: " da tabela estão seleccionados.",
  table_selected_snackbar: " de la tabla han sido seleccionados.",
  select_all_of: "Seleccionar todos os ",
  clear_selection: "Limpar selección",
  add: "Agregar",
  continue: "Continuar",
  copy: "Copiar",
  define: "Definir",
  define_as: "Definir como",
  define_password_policy_group:
    "Establecer política de caducidad de contraseña para este turno",
  details: "Detalles",
  to_view: "Ver",
  preview: "Visualización",
  match: "Corresponder a",
  never: "Nunca",
  or: "ou",
  set_quantity: "Establecer la cantidad",
  basic_info: "Información básica",
  access_permissions: "Permisos de acceso",
  password_policy: "Política de contraseñas",
  has_to_change_password_by_period:
    "Los participantes de este turno deben cambiar sus contraseñas cada ",
  day_of_week: "Día da semana",
  month: "mes",
  monthly: "Mensual",
  days: "días",
  weeks: "semanas",
  years: "años",
  hours: "horas",
  minutes: "minutos",
  day: "día",
  week: "semana",
  year: "año",
  minute: "minuto",
  each: "cada",
  today: "Hoy",
  incorrect_dates: "Una o más fechas ingresadas son incorrectas",
  choose_days: "Elige los días",
  choose_day: "Elige el día",
  days_upper: "Días",
  only_today: "Today only",
  holiday: "Feriado",
  special_date: "Fecha especial",
  everyday: "Todos los dias",
  this_week: "Esta semana",
  this_month: "Este mes",
  this_day: "Este dia",
  everyday_in_this_week: "Todos los días de esta semana",
  weekly: "Semanalmente",
  start_hour: "Hora de inicio",
  end_hour: "Hora de finalización",
  start_date: "Fecha de inicio",
  end_date: "Fecha final",
  loading: "Cargando...",
  no_data: "No hay datos para mostrar",
  no_results: "Sin resultados",
  new_password: "Nueva contraseña",
  confirm_new_password: "Confirmar nueva contraseña",
  confirm_password: "Confirmar contraseña",
  confirm: "Confirmar",
  request_password_change_label:
    "Cambiar contraseña en el primer inicio de sesión",
  no: "No ",
  required_field: "Campo obligatorio",
  indicates_required_field: "indica un campo obligatorio",
  invalid_email: "E-mail inválido",
  invalid_phone_number: "Número de teléfono inválido",
  max_20_chars: "Máximo de 20 caracteres",
  code_6_digits: "El código deve contener 6 dígitos.",
  passwords_do_not_match: "Las contraseñas no coinciden",
  logout_settings: "Configuración de cierre de sesión",
  access_settings: "Configuración de acceso",
  rules: "Reglas",
  no_rules_for_today: "No se fijó cierre de sesión para hoy",
  no_autologout: "Sin cierre de sesión configurado",
  participants: "Participantes",
  participant: "Participante",
  allowed_ip_addresses: "Endereços IP permitidos",
  holidays_and_special_dates: "Feriados y datos especiales",
  special_dates_step_1: "Seleccionar feriados",
  special_dates_step_2: "Ingresar feriados manualmente",
  special_dates_step_1_description:
    "Utilize el calendario o la tabla para seleccionar datas especiales. El botón abajo selecciona los feriados solamente.",
  special_dates_step_2_description:
    'Las datas seleccionadas también son presentadas en el campo abajo. Para guardar sus modificaciones, haga click en "Guardar".',
  special_dates_table_title: "Feriados y datas comemorativas",
  special_dates_get_error: "No ha sido posible obtener los feriados.",
  special_dates_select_holidays: "Seleccionar solo feriados nacionales",
  direct_participants: "Participantes directos",
  admins: "Administradores",
  business_hours: "Jornada de trabajo",
  auto_logout: "Cerrar sesión automáticamente",
  auto_logout_after_closing_window: "Cerrar sesión automáticamente",
  auto_logout_after_closing_window_description:
    "El usuario cerró la ventana de administración",
  no_title: "Sin titulo",
  yes: "Sí",
  all_day: "Todo el día",
  list_ip: "Lista de IPs permitidos",
  invalid_request:
    "La solicitud no es válida y el servidor no pudo procesarla. Verifique sus permisos de acceso y vuelva a intentarlo más tarde.",
  that_is_all_we_know: "Eso es todo lo que sabemos.",
  this_is_an_error: "Esto es un error",
  edition: "edición",
  remove: "Eliminar",
  combobox_helper:
    "Después de escribir, presione <code>ENTER</code> o <code>SPACE</code> para ingresar un nuevo elemento.",
  date_format: "Formato de fecha: DD/MM/AAAA",
  reset_selection: "Restablecer la selección",
  result: "Resultado",
  no_date: "Sin fecha para mostrar",
  except: "excepto",
  set_up: "Configurar",
  no_group: "sin turno",
  you_dont_have_groups: "No tienes turnos",
  notice_to_employees_title: "Notificar a los colaboradores",
  notice_to_employees_description: `Informe a los colaboradores sobre los cambios en el acceso al espacio de trabajo. No se preocupe, haga clic en "Más información" y verá un mensaje sugerido para compartir`,
  check_as_complete: "Marcar como completo",
  your_answer: "Su respuesta",
  call_support: "En caso de duda, llame a soporte!",
  vacation_settings_tip:
    "Seleccione las fechas de <strong>inicio</strong> y <strong>finalización</strong> del período de vacaciones",
  vacation_placeholder: "Seleccionar periodo de vacaciones",
  from: "De",
  until: "a",
  reason: "Razón",
  comment: "Comentario",
  user_block_hour: "Fuera del horario permitido",
  user_success: "Usuario registrado",
  user_wrong_password: "Contraseña invalida",
  user_on_vacation: "Usuario de vacaciones",
  groups_without_rules: "Sin regla de tiempo establecida",
  user_disabled_domain: "Dominio deshabilitado",
  user_block_day: "Fuera del horario permitido",
  user_special_block_day: "Día especial",
  user_block_ip: "IP no permitida",
  user_without_group: "Turno no autorizado para inicio de sesión",
  user_without_active_group: "No pertenece a ningún turno activo",
  user_not_found: "Usuario no encontrado",
  access_logs: "Registros de acceso",
  last_seven_days: "Los últimos 7 días",
  last_three_months: "Últimos 3 meses",
  last_six_months: "Últimos 6 meses",
  download_csv: "Descargar CSV",
  ip_address_description:
    "Dirección única que identifica un dispositivo en Internet",
  browser: "Navegador",
  operational_system: "Sistema operativo",
  operational_system_description:
    "El sistema operativo es el programa que le permite comunicarse con su computadora.",
  event: "Evento",
  login_attempt: "Intento de inicio de sesión",
  user_block_navigator: "Navegador no autorizado para acceder",
  user_block_operational_system: "Sistema operativo no autorizado para acceder",
  user_unknown_ponto_mais: "Usuario no registrado en VR Gente | PontoMais",
  user_unknown_senior: "Usuario no encontrado en Senior",
  user_block_ponto_mais: "Consulta tu registro en VR Gente | PontoMais",
  user_block_senior:
    "Consulta tu registro en la aplicación  Marcação de Ponto 2.0",
  unknown_error: "Error desconocido",
  user_logout_success: "Cerró su sesión",
  logout_success: "Cierre de sesión forzado",
  advanced_auto_logout: "Cierre de sesión automático",
  single_access_logout: "Cierre de sesión automático",
  author_user: "Actor",
  author_description: "El usuario que realizó la acción",
  description: "Descripción",
  force_logout_description: "terminó la sesión",
  closed_office: "Sesión cerrada por regla de turno",
  end_of_single_access: "Fin del inicio de sesión temporal",
  pontomais_auto_logout: "Cierre de sesión automático",
  senior_auto_logout: "Cierre de sesión automático",
  pontomais_auto_logout_description:
    "Sesión terminada por integración de checador",
  more_description:
    "Para usar esta opción, debe tener al menos un usuario seleccionado",
  watch_the_video: "Ver el vídeo",
  vacation_mode_video_title:
    "Modo vacaciones para usuarios de Google Workspace con Conecta Control",
  dismiss: "Descartar",
  ip_warning:
    "Para saber tu IP actual, <strong><a href='https://www.dnsleaktest.com' target='_black'>haga clic aquí</a></strong>. Si tiene preguntas sobre cómo funcionan las direcciones IP en su empresa, consulte a su equipo de TI.",
  start: "Inicio",
  end: "Fin",
  duration: "Duración",
  records: "Registros",
  hi: "Hola",
  schedule_meeting_description:
    "¡Bienvenido a Conecta Control! Como esta es su primera vez aquí, puede programar una reunión con nuestro equipo para obtener más información sobre la herramienta. ¿Le gustaría programar una reunión?",
  maybe_later: "Tal vez más tarde",
  user_comments: "Deja un comentario",
  thanks_for_feedback: " ¡Agradecemos por sus comentarios!",
  new_feedback: "Nuevo comentario",
  use_default_password: "Utilizar contraseña predeterminada",
  cancel_activation_sso_async: "activación automática cancelada de Control",
  automatic_activation: "Activación automática",
  user_activate_sso_async: "activó el control de forma asíncrona",
  suspended_account: "Cuenta suspendida",
  contact: "Contacta con nosotros",
  contact_for_resolution:
    "Ponte en contacto con nosotros para resolver problemas de pago",
  expired_code: "Código caducado",
  code_expires: "El código caduca en",
};

const scopesDialog = {
  scopesDialog_title:
    "Para completar su inicio de sesión, acepte todos los permisos de Conecta Control en Google Admin Console.",
  scopesDialog_permissions:
    "Necesitamos estos permisos para ofrecerle una experiencia completa.",
  scopesDialog_privacy:
    "Su privacidad y seguridad son nuestra prioridad, y puede revisar los permisos en cualquier momento.",
  scopesDialog_faq: `Si tiene dudas, acceda a las <a href="https://conectasuite.com/perguntas-frequentes/escopos-google-workspace/" target="_blank"><b>Preguntas Frecuentes</b></a> o contáctenos.`,
  scopesDialog_acceptAll: `Acceda a Google Admin Console y haga clic en "Permitir el acceso" para continuar.`,
  scopesDialog_goToAdminConsole: "Ir al panel de Google Admin Console",
  scopesDialog_goToPanel: "Ir al panel",
};

const reports_page = {
  reports_loginlogout_title: "Informe de login y logout",
  reports_loginlogout_description:
    "Registros de login y logout de los usuarios",
  reports_users_title: "Informe de usuarios",
  reports_users_description: "Registros de acciónes en los usuarios",
  reports_groups_title: "Informe de turnos",
  reports_groups_description: "Registros de acciónes en los turnos",
  reports_access_time_title: "Informe de tiempo de aceso",
  reports_access_time_description:
    "Registros de tiempo de aceso de los usuários por dia",
  folders_sharing: "Archivos y carpetas",
  folders_sharing_description: "Informe de uso compartido externo",
  shared_files: "Archivos compartidos",
  files_shared_externally: "Archivos compartidos externamente",
  full_size: "Tamaño total",
  sharing: "Intercambio",
  users_with_access: "Usuarios con acceso",
  size: "Tamaño",
  extension: "Extensión",
  anyone_with_link: "Cualquiera con el link",
  external: "externo",
  internal: "interno",
  access_time_start: "Primero login.",
  access_time_end: "Primero logout después del último login.",
  access_time_duration:
    "Período en que el usuario estuvo conectado a Google, pudiendo ser diferente del tiempo en el que estuvo en la máquina.",
  access_time_duration_missing_data:
    "Duración estimada: sesión continua hasta el final del día.",
  access_time_duration_not_finished:
    "Duración parcial: sesión continua activa el día corrente.",
  access_time_duration_cannot_calculate:
    "Sin inicio: falta el registro de login para calcular la duración de la sesión en el día.",
  upgrade_plan: "Plan de alquiler",
  report_user_permissions_title: "Informe de permisos de usuario",
  report_customize_login_title:
    "Informe de personalización de la pantalla de inicio de sesión",
  customize_login_label: "Personalización de inicio de sesión",
};

/* AÇÕES EM GERAL */
const button_actions = {
  add_filter: "Agregar filtros",
  next: "Siguiente",
  previous: "Anterior",
  more: "Aplicar a seleccionado",
  add_selected_to_group: "Agregar seleccionados a un turno",
  add_user_to_group: "Agregar usuario a un turno",
  add_users_to_shift: "Agregar usuarios a un turno",
  manage_user_groups: "Administrar turnos de usuario",
  remove_selected_from_this_group: "Eliminar seleccionados de un turno",
  remove_user_from_this_group: "Eliminar usuario de este turno",
  remove_user_from_a_group: "Eliminar usuario de un turno",
  select_all: "Seleccionar todos los",
  apply: "Aplicar",
  active_control: "Activar Conecta Control",
  force_logout_to_all_users: "Cierre la sesión de todos los usuarios",
  redirect_to_force_logout:
    "Seleccionar usuarios para forzar el cierre de sesión",
  inactive_control: "Desactivar Conecta Control",
  change_password: "Cambiar contraseña",
  reset: "Restablecer",
  reset_password: "Restablecer contraseña",
  reset_password_label:
    "La nueva contraseña debe cumplir con las reglas establecidas en <b>Configuración de contraseña > Configurar la seguridad de la contraseña</b>.",
  ask_force_logout_reset_password: "Cerrar sesión en todos los dispositivos",
  ask_force_logout_reset_password_tooltip:
    "Cuando está habilitada, esta opción cerrará la sesión de la cuenta de usuario de todos los dispositivos conectados",
  ask_reset_password_first_access: "Cambiar la contraseña al iniciar sesión",
  ask_reset_password_first_access_tooltip:
    "Al habilitar esta opción, en el próximo inicio de sesión deberá ingresar la contraseña definida aquí para luego poder restablecer su propia contraseña manualmente.",
  request_password_change: "Solicitar cambio de contraseña",
  change_user_password: "cambiar contraseña de usuario",
  user_password_change: "Cambio de contraseña",
  change_users_password: "cambiar contraseña de los usuarios",
  request_logout: "Forzar cierre de sesión de los usuarios",
  grant_single_access: "Liberar acceso temporal",
  remove_single_access: "Eliminar acceso temporal",
  request_users_logout: "Forzar el cierre de sesión de los usuarios",
  edit_groups: "Editar los turnos de este usuario",
  change_permissions: "Cambiar permisos",
  changes: "cambios",
  close: "Cerrar",
  save: "Guardar",
  cancel: "Cancelar",
  add_selected: "Agregar usuarios seleccionados",
  more_link_text: "Más información",
  verify: "verificar",
  disable_control: "deshabilitar el control",
  click_here: "Haga clic aquí",
  click_to_check_to_conclude: "Clique para marcar como concluido",
  click_to_uncheck_to_conclude: "Clique para remover item de concluidos",
  accept_scopes: "Aceptar alcances",
  see_plan: "VER PLANE",
  see_integrations: "VER INTEGRACIONES",
  request_single_access: "Solicitar acceso temporal",
  user_request_single_access: "Usuario solicitó acceso temporal",
  user_email_hint:
    "Ingrese la dirección de correo electrónico a la que desea acceder",
  requested_single_access: "solicitó acceso temporal",
  requestSingleAccess: "Acceso temporal solicitado",
  request_password_change_text: "Solicitar Cambio de Contraseña",
  user_request_password_change: "Usuario solicitó un cambio de contraseña",
  requested_password_change_description: "solicitó un cambio de contraseña",
  to_admins: "a los administradores",
  reports: "Informes",
  reports_description:
    "Seguimiento de los informes de actividad de los usuarios",
  reports_loginlogout: "Login y logout",
  reports_users: "Usuarios",
  reports_groups: "Turnos",
  reports_access_time: "Tiempo de aceso",
  dismiss_text: "Descartar",
  restore_default_color: "Restaurar el color predeterminado",
  activate_selected_shifts: "Activar turnos seleccionados",
  shift_message_in_period_in_bulk: `Uno o más turnos tienen definido el período de activación, luego de la confirmación, esta configuración será eliminada.`,
  continue_without_code: "Continuar sin codigo",
  go_to_marketplace: "Ir al Marketplace",
};

const password_settings_page = {
  set_default_password: "Configurar contraseña por defecto",
  password_settings_subtitle:
    "La contraseña predeterminada es la contraseña que se les pedirá a sus usuarios que usen cuando inicien sesión por primera vez o cuando soliciten cambiar su contraseña.",
  defineTemporaryPasswordTip:
    "Sus usuarios tendrán que utilizar esta contraseña al iniciar sesión por primera vez.",
  default_password: "Contraseña predeterminada",
  new_initial_password: "nueva contraseña temporal",
  password_security: "Configurar la seguridad de la contraseña",
  password_settings_subtitle2:
    "Elija qué valores predeterminados deben tener las contraseñas de sus usuarios",
  min_len_label: "Número mínimo de caracteres:",
  must_have_a_upper_letter: "Debe incluir al menos una letra mayúscula.",
  must_have_a_lower_letter: "Debe incluir al menos una letra minúscula.",
  must_have_a_number: "Debe incluir al menos un número.",
  must_have_a_symbol: "Debe incluir al menos un carácter especial.",
  chackbox_label: "Las contraseñas deben contener:",
  checkbox_symbols_label: 'Caracteres especiales (Ex: “!, @, %, #")',
  checkbox_uppercase_label: 'Letras mayúsculas (de la "A" a la Z")',
  checkbox_lowercase_label: "Letras minúsculas (de la “a“ a la “z”)",
  checkbox_numbers_label: "Número (de la “0” a la “9”)",
  rename: "Renombrar",
  disable: "Deshabilitar",
  edit: "Editar",
  name: "Nombre",
  delete: "Eliminar",
  action_cannot_undo: "No se puede deshacer esta acción.",
  action_can_undo: "Esta acción se puede deshacer en cualquier momento.",
  activate: "Activar",
  create: "Crear",
  success_update_default_password_message:
    "Nueva contraseña predeterminada guardada con éxito!",
  error_update_default_password_message:
    "Hubo un error al guardar la contraseña, intente nuevamente más tarde.",
  success_update_password_strength:
    "¡La configuración de seguridad de la contraseña se guardó correctamente!",
  error_update_password_strength:
    "Hubo un error al guardar la configuración de la contraseña, inténtalo de nuevo más tarde.",
  current_password: "Contraseña actual",
  has_initial_password: "Contraseña configurada",
  no_initial_password: "Sin contraseña configurada",
  password_successful_copy: "La contraseña ha sido copiada",
  password_unable_to_copy: "Unable to copy password, please try again",
  theAdminMustChangeHisOwnPassword:
    "<strong>Los administradores que utilicen el dominio seleccionado también se verán afectados</strong>",
  adminsMustChangePassword:
    "<strong>Los administradores del dominio seleccionado también deben cambiar la contraseña.</strong>",
};

const integrations_page = {
  your_token: "tuToken",
  logout_webhook_copied_successfully: "Webhook copiado con éxito",
  current_webhook: "Enlace de webhook",
  autologout_by_pontomais:
    "Cierre de sesión automático a través de VR Gente | PontoMais",
  request_integration_activation: "Solicitar activación de integración",
  ponto_mais_control: "Reloj checador con VR Gente | PontoMais",
  tangerino_control: "Control de horario con Sólides Tangerino",
  clock_time_control_unavailable:
    "La integración de checador solo está disponible para el plan Enterprise, actualice para liberar esta funcionalidad.",
  pontomais_token_not_found:
    "No ha sido encontrado un Token de la VR Gente | PontoMais",
  tangerino_token_not_found:
    "No ha sido encontrado um token de integración de Sólides Tangerino",
  invalid_senior_credentials:
    "Credenciales de aplicación Marcação de Ponto 2.0 no válidas",
  activate_integrations: "HABILITAR INTEGRACIONES",
  active_directory_unavailable:
    "La integración con Active Directory solo está disponible para el plan Enterprise, actualice para liberar esta funcionalidad.",
  ponto_mais_integration_subtitle:
    "Configura la cuenta de tu empresa VR Gente | PontoMais token para combinar el servicio de punto electrónico y los beneficios de Conecta Control",
  tangerino_integration_subtitle:
    "Configura el token de integración de Sólides Tangerino de tu empresa, para combinar el servicio de reloj de punto electrónico y los beneficios de Conecta Control.",
  current_token: "Token actual",
  active_directory_sync: "Sincronización con Active Directory (AD)",
  active_directory_integration_subtitle:
    "Sincroniza Windows Active Directory con Conecta Control AD ​​y simplifica la gestión de usuarios y sus contraseñas",
  copy_paste_pontomais_autologout_function:
    "Para cerrar automaticamente la sesión de los usuarios después de registrar un punto de salida, copie y pegue el enlace en la configuración de webhook de registro de punto de VR Gente | PontoMais.",
  download_executable: "descargar el ejecutable",
  new_token: "Nuevo token",
  save_token: "Guardar token",
  ponto_mais_token_integration_title:
    "Guarde el token de integración con VR Gente | PontoMais",
  tangerino_token_integration_title:
    "Configurar token de integración con Sólides Tangerino",
  update_ad_token_title: "Actualizar token de Active Directory",
  update_ad_token_action: "Actualizar token de AD",
  update_ad_token_confirm_phrase:
    "¿Está seguro de que desea actualizar el token? En caso afirmativo, para mantener la integración deberá actualizar el token en su servidor.",
  error_update_ad_token: "No se pudo actualizar el token de AD",
  success_update_ad_token: "Token actualizado con éxito",
  error_update_ponto_mais_token:
    "Error al actualizar el token de VR Gente | PontoMais",
  invalid_ponto_mais_token: "El token proporcionado no es válido",
  success_update_ponto_mais_token:
    "Token de VR Gente | PontoMais actualizado con éxito",
  success_update_token: "Token de integración guardado con éxito",
  token_copied_successfully: "¡Token copiado con éxito!",
  marcacao_ponto_senior_control:
    "Control de tiempo con Marcação de Ponto 2.0 HCM | Senior",
  senior_credentials_description:
    "Configura las credenciales de la aplicación registrada en la Plataforma Senior X para combinar el checador con los beneficios de Conecta Control",
  config_credentiais: "Configurar credenciales",
  edit_credentiais: "Editar credenciales",
  access_key: "Llave de acceso",
  secret: "Secreto",
  tenant_name: "Nombre del inquilino",
  clock_time_provider_credentials_not_found:
    "No se encontraron las credenciales de acceso para el proveedor de checador seleccionado",
};

const sso = {
  disable_sso_warning:
    "Conecta Control está deshabilitado, la configuración en el panel solo se aplicará después de la activación por parte de un administrador.",
  disable_sso_admin_warning:
    "¡Conecta Control está desactivado! Vaya a la configuración de arriba ",
  and_click_on: " y haga clic en ",
  activate_sso_warning: "Hay ",
  activate_sso_warning2: " ningún turno asignado",
  activate_sso_warning3:
    ". Los usuarios sin turno no pasarán la verificación de Conecta Control.",
  activate_sso_success: "Conecta control activado con éxito!",
  activate_sso_error: "No se puede activar Conecta control",
  disable_sso_success: "Conecta Control desactivado con éxito!",
  disable_sso_error: "No se puede deshabilitar Conecta control",
  activate_sso_label:
    "La siguiente acción activará el control de acceso para todos los usuarios en su espacio de trabajo.",
  action_reversible:
    "Un administrador puede deshacer esta acción en cualquier momento a través del menú de configuración.",
  continue_activation: "¿Desea continuar con la activación?",
  dont_force_logout: "No fuerce el cierre de sesión",
  force_users_logout:
    "Forzar a los usuarios a cerrar sesión para iniciar sesión a través del Control:",
  schedule_activation_alert_description:
    "La activación del Conecta Control está programada para",
  cancel_schedule_activation_dialog_title:
    "Cancelar programación de activación",
};

const errors = {
  unavailable_group_information: "Ocurrió un error al cargar este turno",
  error_update_group: "No se puede actualizar la información de este turno",
  error_rules_update:
    "No se pueden guardar los cambios en las reglas de este turno",
  error_logout: "No se puede cerrar la sesión",
  error_create_group:
    "No se pudo crear un nuevo turno, intente nuevamente más tarde",
  error_load_groups:
    "Error al cargar turnos, verifique sus permisos de acceso con el administrador",
  error_add_admin:
    "No se puede agregar el permiso de administrador para este usuario, verifique sus permisos",
  error_remove_admin:
    "No se puede eliminar el permiso de administrador para este usuario, verifique sus permisos",
  error_user_page: "Ocurrió un error al cargar este usuario",
  error_add_user_to_shift:
    "El usuario no se puede agregar este turno. Inténtalo de nuevo.",
  error_remove_user_from_group:
    "El usuario no puede ser eliminado de este turno. Inténtalo de nuevo.",
  error_remove_users_from_group:
    "No se pudieron eliminar usuarios de este turno. Inténtalo de nuevo.",
  error_participants_to_add_not_found:
    "No se pudieron agregar una o más direcciones de correo electrónico ingresadas",
  error_invalid_emails:
    "Una o más direcciones de correo electrónico ingresadas no son válidas, corríjalas y vuelva a intentarlo.",
  error_invalid_emails_examples:
    "Las siguientes direcciones de correo electrónico no son correos electrónicos válidos:",
  some_emails_could_not_be_added:
    "No se pudieron agregar algunos correos electrónicos",
  error_force_password_change:
    "Ocurrió un error al solicitar cambiar las contraseñas de los usuarios seleccionados",
  error_change_password: "Error al cambiar la contraseña, intente nuevamente",
  error_vacation_mode:
    "Error al activar el modo de vacaciones, inténtalo de nuevo",
  error_remove_vacation_mode:
    "No se ha podido eliminar el modo de vacaciones, inténtalo de nuevo",
  error_grant_single_access: "Error al liberar el inicio de sesión temporal.",
  error_remove_single_access: "Error al eliminar el inicio de sesión temporal.",
  service_unavailable: "Servicio no disponible. ¡Inténtalo de nuevo más tarde!",
  error_user_logs:
    "Hubo un error al cargar el historial de inicio de sesión. ¡Inténtalo de nuevo más tarde!",
  error_logout_users_suspended:
    "No se puede forzar el cierre de sesión de los usuarios suspendidos",
  starthour_later_than_endhour:
    "La hora de inicio no puede ser posterior a la hora de finalización.",
  MISSING_LOGO: "No se puede guardar su logotipo",
  MISSING_PRIMARY_COLOR: "No se pudo guardar el color primario",
  MISSING_SECONDARY_COLOR: "No se pudo guardar el color secundario",
  not_found: "No encontrado",
  error_save_senior_credentials:
    "No se puede completar la configuración. Verifique sus credenciales e inténtelo nuevamente",
  error_user_already_exists: "Usuario ya existe en su listado de usuarios",
  error_shared_password:
    "Se produjo un error al compartir contraseñas por correo electrónico, inténtelo más tarde.",
  error_send_email_to_change_password:
    "Ocurrió un error al enviar los correos, por favor espera un momento y vuelve a intentarlo.",
  scope_error: "No se puede completar la acción debido a la falta de alcances",
  error_reports: "Se produjo un error al cargar el informe. Inténtalo de nuevo",
  error_activation_async:
    "Se produjo un error durante la programación de la activación. Inténtelo de nuevo",
  error_cancel_activation_async:
    "Se produjo un error durante la cancelación de la activación automática. Inténtelo de nuevo",
  INVALID_TANGERINO_TOKEN:
    "El token de integración informado no es valido! Verique el token y inténtelo de nuevo",
};

const success = {
  success_group_update:
    "La información de este turno ha sido actualizada con éxito",
  success_rules_update: "Las reglas de este turno han sido actualizadas",
  success_logout: "Sesión de usuario cerrada",
  success_users_logout:
    "Las sesiones de los usuarios activos han sido canceladas",
  success_create_group: "Turno creado con éxito",
  success_add_admin: "Permiso de administrador concedido para",
  success_remove_admin: "Permiso de administrador eliminado de",
  success_remove_user_from_group: "El usuario ha sido eliminado del turno",
  success_remove_users_from_group: "Los usuarios han sido eliminados del turno",
  success_add_user_to_a_group: "se agregó al turno",
  success_add_user_to_many_groups: "se ha agregó a varios turnos",
  success_add_users_to_group:
    "Los usuarios han sido agregados al turno con éxito",
  success_force_password_change:
    "Se ha aplicado la solicitud de cambio de contraseña a los usuarios seleccionados",
  success_change_password: "La contraseña ha sido actualizada",
  success_vacation_mode: "¡El modo de vacaciones se ha aplicado con éxito!",
  success_remove_vacation_mode: "Se eliminó el modo de vacaciones!",
  success_grant_single_access:
    "¡El inicio de sesión temporal se ha lanzado con éxito!",
  success_remove_single_access:
    "¡El inicio de sesión temporal se ha eliminado correctamente!",
  success_request_single_access: "¡Tu solicitud fue exitosa!",
  success_request_password_change:
    "¡Tu solicitud de cambio de contraseña fue enviada con éxito!",
  saved_logo:
    "¡Su logotipo se ha guardado y ahora está disponible en la página de inicio de sesión!",
  customize_login_success:
    "Los cambios se han guardado y ahora están disponibles en la pantalla de inicio de sesión",
  remove_logo_success: "¡El logotipo de la empresa se eliminó con éxito!",
  remove_image_success: "¡La imagen se eliminó con éxito!",
  save_senior_credentials: "Las credenciales se guardaron correctamente",
  success_ms_user_created: "Usuario ha sido creado y sincronizado con éxito!",
  shared_password_success:
    "Se generará una contraseña aleatoria y se compartirá de forma segura por correo electrónico con sus empleados. Próximamente recibirán la contraseña de acceso.",
  shared_email_password_success:
    "Los correos han sido enviados a tus colaboradores.",
  success_schedule_activation:
    "¡La activación del Conecta Control se ha programado correctamente!",
  success_cancel_schedule_activation:
    "¡La programación de la activación automática se ha cancelado correctamente!",
};

const warnings = {
  warning_control_user_created:
    "Usuario ha sido creado, pero no ha sido posible sincronizarlo. Intente más tarde sincronizar los usuarios, o póngase en contacto con el suporte.",
};

const plan_status = {
  user_expired_trial: "Periodo de prueba expirado",
  disabled_or_not_found: `Este producto no está activo para su empresa. Contrata un \
    <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plan para activarlo</a> \
    o contacta con nuestro equipo comercial para seguir usando Conecta Control: experience@conectasuite.com`,
  expired_trial: `Su período de prueba ha caducado. Contrata un \
    <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plan para activarlo</a> \
    o contacta con nuestro equipo comercial para seguir usando Conecta Control: experience@conectasuite.com`,
  expiring_trial: `[Su prueba expira en <strong>] DD/MM/YYYY. [</strong> Contrata un \
    <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plan para activarlo</a> \
    o contacta con nuestro equipo comercial para seguir usando \
    Conecta Control: experience@conectasuite.com]`,
  company_not_found:
    "Su empresa no fue encontrada en Conecta Suite. Contacta con nuestro equipo comercial para seguir \
    usando Conecta Control: experience@conectasuite.com",
  product_unavailable: "Este producto no está activo para su empresa",
  go_to_suite_plans: "Contrata un plan para activarlo",
  make_plan_upgrade: "Mejora tu plan",
  contract_a_plan: "Contrata un plan",
  know_the_plans: "Conoce los planes",
  contract_control: "¡Contrata Conecta Control ahora!",
  more_resources_dense:
    "Más funciones: controle el tiempo, la ubicación y el acceso del navegador a Workspace",
  more_resources:
    "Desbloquea más funciones, controla el acceso por hora, ubicación y navegador a Workspace con Conecta Control",
  contract_expired_trial:
    "¡Adquiere un plan para seguir disfrutando de todas las funcionalidades de los productos Conecta Suite!",
  contract_plan_while_trial:
    "Asegure su plan ahora sin perder los días de prueba gratuitos restantes. ¡Desde R$2,50/usuario/mes!",
  plan_is_expired_trial: "¡Ups, tu período de prueba ha terminado!",
  enjoying_control: "¿Estás disfrutando de Conecta Control?",
  last_trial_day: "Último día de prueba",
  expired: "Venció",
  testing_text: "Estas probando",
  days_remaining: "Días restantes",
  remaining_day: "Día restante",
  company_information: "Informacion de la empresa",
  request_activation: "Solicitar activación",
  test_ends_in: "La prueba termina en",
  test_ends_today: "La prueba termina hoy",
  test_ended_in: "Prueba termino en",
  licenses_number: "N° de licencias",
  created_at: "Creado en",
  products: "Productos",
  activation_error_disabled_plan_title:
    "Vaya, no se puede activar el control de acceso",
  plan_details: "Detalles del Plan",
  plan: "Plan",
  plan_payment: "Pago del plan",
  /* Plan Periodic */
  flex: "Mensual",
  annual: "Anual",
  trial: "Prueba",
  quartely: "Trimestral",
  semester: "Semi anual",
  biennium: "Bienal (2 años)",
  extra: "Extras",
};

const snackbarActivationErrorMessages = {
  DISABLED_OR_NOT_FOUND:
    "No fue posible activar el control de acceso ya que Conecta Control no forma parte de ningún plan contratado o en prueba",
  EXPIRED_TRIAL:
    "No se pudo activar Conecta Control porque el período de prueba ha expirado",
};

const reset_password = {
  reset_description:
    "Elija una contraseña segura, preferiblemente una que contenga símbolos, números, Letras mayúsculas y minúsculas.",
  REQUIRED_BASIC_AUTH: "Se requiere autenticación para realizar esta operación",
  INCORRECT_CREDENTIALS: "Usuario o contraseña incorrectos",
  INVALID_PASSWORD: "La contraseña no cumple con uno o más requisitos",
  SAME_PASSWORD: "Neuva contraseña idéntica a la actual.",
  USER_NOT_FOUND: "No se encontró el usuario especificado",
  REQUEST_LIMIT_EXCEEDED:
    "Ha alcanzado el número máximo de solicitudes permitidas para esta operación, vuelva a intentarlo en unos minutos.",
  min_len: "La contraseña debe contener el número mínimo",
  min_len_sufix: " de caracteres.",
  has_upper: "La contraseña deberá contener al menos 1 letra mayúscula.",
  has_lower: "La contraseña debe contener al menos una letra minúscula.",
  has_number: "La contraseña debe contener al menos un número.",
  has_symbol: "La contraseña debe contener al menos un carácter especial.",
  at_least_an_upper: "Al menos una mayúscula",
  at_least_an_lower: "Al menos una letra minúscula",
  at_least_an_number: "Al menos un número",
  at_least_an_symbol: "Al menos un carácter especial",
  is_the_same: "The new password must be different from the current one.",
};

const productNames = {
  conecta_suite: "Conecta Suite",
  conecta_control_mobile: "Advanced Logout",
  conecta_control: "Conecta Control",
  conecta_sign: "Conecta Sign",
  conecta_ad_sync: "Active Directory Integration",
  conecta_track: "Conecta Track",
  integracao_ponto_mais: "VR Gente | PontoMais Integration",
  sign_automation: "Sign Automation",
};

const priceChangeWarning = {
  priceChangeWarning:
    "A partir del <strong>1 de julio de 2024</strong>, los precios de los planes Standard, Security y Enterprise de Conecta Suite se ajustarán según la tabla disponible en el sitio web",
  noActionNeeded: "No se requiere ninguna acción.",
  willingToHelp:
    "Estamos disponibles para responder a sus preguntas vía correo electrónico",
  thanks4BeeingClient: "Gracias por ser cliente de Conecta Suite.",
  iUnderstood: "Yo entendí",
  priceChangeSubtitle: "Aviso sobre cambios en el precio del plan",
  priceAdjustment: "Ajuste en el valor de los Planes",
};

const selfCheckout = {
  buy: "Comprar",
  currentPlan: "Plan actual",
  freeTrialPeriod: "Gratis (14 días)",
  economyOf: "Ahorro de ",
  annualPayment: "Pago Anual",
  monthlyPayment: "Pago Mensual",
  license: "Licencia",
  licenses: "Licencias",
  licenseNumber: "Número de licencias",
  paymentPlan: "Plan de pago",
  free: "Gratis",
  reportError: "Reportar error",
  initBilling: "Inicio de facturación",
  redirectingIn: "Serás redirigido en ",
  registerPlan: "¡Vaya! Error al registrar la información del plan",
  youBeeingRedirected: "Serás redirigido(a) para completar el pago...",
  savingPlanInfo: "Guardando la información de tu nuevo plan...",
  paymentMinimumValueTextforAnnual:
    "Añade licencias adicionales para continuar en esta modalidad.",
  paymentMinimumValueTextforFlex:
    "Selecciona el plan de pago anual para continuar",
  paymentMinimumValue: "¡No se ha alcanzado el valor mínimo de compra de R$40!",
  cart: "Carrito",
  licensesTipForAnnual:
    "Establece un número adicional de licencias para suscribirte al plan Anual. El número mínimo corresponde al número de usuarios de tu Google Workspace.",
  creditCardPaymentInstructions:
    "Paga con tarjeta de crédito a través de Superlógica",
  bankSlip: "Boleto",
  bankSlipPaymentTitle: "¡Suscripción realizada con éxito!",
  receiveBankSlipEmail: "Recibe el boleto de pago por correo electrónico",
  orderDetails: "Detalles del pedido",
  bankSlipDueDate: "Fecha de vencimiento del boleto",
  paymentType: "Forma de pago",
  creditCard: "Tarjeta de crédito",
  finishBuy: "Finalizar Compra",
  clearCart: "Limpiar Carrito",
  removePlanFromCart: "Eliminar plan del carrito",
  confirmPlanPurchaseText: "Confirmar Compra",
  implemantationHelp:
    "¿Te gustaría la ayuda del equipo de Conecta Suite para la implementación?",
  goToPayment: "Ir al pago",
  confirmNewPlan: "Confirmar nuevo plan",
  overwriteCurrentPlan:
    "Ya tienes uno o más productos del plan que deseas adquirir. Al continuar, aceptas que el plan actual sea desactivado y se suscriba el nuevo.",
  confirmPurchaseText:
    'Al hacer clic en "Confirmar Compra," contratarás el plan',
  authorizeCharge: "y autorizas el cobro del valor especificado.",
  newPlan: "Nuevo plan",
  trialEnded: "Tu período de prueba ha terminado",
  trialEndedText:
    "Adquiere un plan para continuar disfrutando de todas las funcionalidades de los productos de Conecta Suite",
  getBasicAfterTrialEnded: "Después del final de la prueba (a partir de",
  simulationAlert:
    "Por razones de seguridad, no está permitido suscribirse a planes durante la simulación de inicio de sesión. <br /> Instruye a un administrador de Google de esta empresa para que realice la operación desde esta pantalla, o hazlo desde la página del cliente en el panel de Conecta",
  notApplicable: "No aplicable",
  helpCardFeatureListTitle1:
    "Accede a SuperLogica y realiza el pago en la plataforma",
  helpCardFeatureListSubtitle1:
    "Tu cuenta en SuperLogica ha sido creada, ¡usa tu correo electrónico para acceder al sitio! La información rellenada en SuperLogica se utilizará para la facturación del plan.",
  helpCardFeatureListTitle2: `¡Pronto recibirás un correo electrónico para realizar el pago con tarjeta de crédito!`,
  helpCardFeatureListSubtitle2: `Si no fuiste redirigido, <a href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/formadepagamento" target="_blank">haz clic aquí</a>.`,
  helpCardFeatureListTitle3: `¡No te preocupes! Enviaremos un correo electrónico de confirmación tan pronto como recibamos tu pago.`,
  helpCardFeatureListSubtitle3: `Para tu seguridad, no guardamos los datos de tu tarjeta de crédito en nuestra base de datos ni en ninguna parte de la aplicación.`,

  helpBankSlipFeatureListTitle1:
    "La información de la suscripción será enviada a los correos electrónicos registrados.",
  helpBankSlipFeatureListSubtitle1:
    "El correo electrónico contiene información importante sobre el plan suscrito, la forma de pago, el valor de la licencia y la fecha de inicio de la facturación.",
  helpBankSlipFeatureListTitle2: `Pronto recibirás un correo electrónico con el boleto para realizar el pago.`,
  helpBankSlipFeatureListSubtitle2: `El boleto también estará disponible en SuperLogica. Puedes acceder al área del cliente <a href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/formadepagamento" target="_blank">haciendo clic aquí</a> e ingresando tu correo electrónico.`,
  helpBankSlipFeatureListTitle3:
    "¡Ya tienes acceso a las herramientas! Siéntete libre de utilizarlas.",
  helpBankSlipFeatureListSubtitle3: "",

  hirePlanSnackbarPrefix: "El plan ",
  hirePlanSnackbarSuffix:
    " fue suscrito con éxito. Se enviará un correo electrónico de confirmación desde",
  hireBasicPlanRule:
    "Este plan estará disponible para suscribirse después de que finalice el período de prueba",
  choosePlanPaymentType: "Elige el plan de pago:",
  trialPeriod: "Período de prueba",
  beingRedirectdToSL: "Serás redirigido a SuperLogica en",
  helpWithPaymentText: "¿Tienes dudas sobre el pago o los planes?",
  accessPageSuportText: `Envía un correo electrónico a <a href="mailto:financeiro@conectasuite.com" target="_blank">
    financeiro@conectasuite.com
  </a> o consulta nuestra página de soporte para resolver tus dudas.`,
  almostThere: "¡Estás casi allí!",
  goToSuperLogica: "Ir a SuperLogica",
  goBackToPlans: "Volver a los planes",
  security_availability:
    "Disponible para Google Workspace (G Suite) y Microsoft 365",
  security_accessControl:
    "Limita el acceso de los empleados al entorno digital de la empresa por horario, sistema operativo, navegador, DNS, VPN e IP",
  security_customShifts:
    "Crea reglas de acceso con turnos personalizados ilimitados",
  security_deviceDisconnect:
    "Desconecta al empleado de todos los dispositivos en el horario configurado automáticamente o con un solo clic",

  security_loginReport:
    "Informe de inicio de sesión y cierre de sesión del correo corporativo",
  security_fileSharingReport:
    "Informe de compartición de archivos corporativos",
  security_customLogin:
    "Pantalla de inicio de sesión personalizada con la información y la identidad visual de la empresa",

  enterprise_availability:
    "Disponible para Google Workspace (G Suite) y Microsoft 365",
  enterprise_securityBenefits: "Beneficios del plan de Security",
  enterprise_seniorIntegration: "Integración con Senior Sistemas",
  enterprise_vrGenteIntegration: "Integración con VR Gente (Pontomais)",
  enterprise_activeDirectory: "Integración con Active Directory (AD)",
  enterprise_tangerino: "Integración con Solides Tangerino",
  enterprise_otherIntegrations: "Integraciones con otros sistemas",
  decreaseLicenseRule:
    "El número de licencias no puede ser inferior al número de usuarios de la empresa",
};

const companyProfile = {
  companyProfile: "Perfil de la Empresa",
  companyInfo: "Información de la Empresa",
  cnpj: "CNPJ",
  site: "Sitio web",
  corporate: "Razón Social",
  financeEmail: "Correo del Financiero",
  adminEmail: "Correo del Administrador",
  neighborhood: "Barrio",
  address: "Dirección",
  number: "Número",
  complement: "Complemento",
  city: "Ciudad",
  state: "Estado",
  country: "País",
  zipCode: "Código Postal",
  companyInfoTitle: "Información de la Empresa",
  companyBillingTitle: "Facturación",
  companyBillingSubtitle: "Administra tus datos de pago.",
  billingInfo: "Información de Facturación",
  requiredEmail: "Correo es obligatorio",
  clearFields: "Limpiar campos",
  missingCNPJAlert:
    "Esta empresa <b>no tiene un CNPJ registrado</b>. Para continuar con la compra es necesario completar la información obligatoria.",
  notRegistered: "No registrado",
  dataUpdated: "¡Datos actualizados con éxito!",
  fail_to_update_company_in_suite:
    "Error al actualizar la información de la empresa",
  DOWNGRADE_BLOCK:
    "No es posible hacer un downgrade del plan en la modalidad anual. Por favor, contáctanos por correo electrónico a experience@conectasuite.com.",
  INVALID_SUBSCRIPTION: "Cambio de periodicidad del plan no permitido",
  fillingRequiredFields: "Complete los datos obligatorios",
  requiredCNPJ: "CNPJ es obligatorio",
  insertingCNPJ: "Insertando CNPJ..",
  NOT_IN_CACHE: "No se pueden leer los datos de este CNPJ",
  LIMIT_EXCEEDED: "Inténtelo de nuevo en un minuto",
  REJECTED_CNPJ: "CNPJ rechazado por la hacienda federal",
  INVALID_CNPJ: "CNPJ inválido",
  BAD_REQUEST_CNPJ: "CNPJ no informado",
  INVALID_COMPANY_CNPJ:
    "No fue posible completar la contratación del plan porque la empresa contratante no tiene un CNPJ registrado en el sistema.",
};

let es = Object.assign(
  {},
  feedback,
  login,
  auth,
  accessForm,
  navbar,
  general,
  users,
  conecta,
  reports_page,
  button_actions,
  sidebar,
  headers,
  home,
  labels,
  groups,
  tour,
  password_settings_page,
  integrations_page,
  errors,
  success,
  warnings,
  sso,
  plan_status,
  welcome,
  activationSteps,
  activationStepsMs,
  adminPermissions,
  reset_password,
  customizeLogin,
  productNames,
  snackbarActivationErrorMessages,
  priceChangeWarning,
  scopesDialog,
  selfCheckout,
  companyProfile
);

module.exports = { es };

import {
  setPathDefaultHubSpotTrack,
  loadHubSpotTracking,
  identifyUserOnHubSpot,
} from "@/helpers/hubspot";
import { getDatabase, ref, onValue } from "firebase/database";

const state = () => ({
  currentUser: null,
  users: [],
  loading: {
    users: false,
    sync: false,
    users_password: false,
    user_password: false,
    force_password_change: false,
    user: false,
  },
  admins: [],
  dialogChangePasswordUser: false,
  selectedUser: false,
  edit: { user_shifts: false },
  firstSyncUsersStatus: {},
});

const getters = {
  firstSyncUsersStatus: (state) => state.firstSyncUsersStatus,
  isControlAdmin: (state) =>
    state.admins.find((user) => user.email === state.currentUser.email) ||
    state.currentUser.is_admin
      ? true
      : false,
  currentUser: (state) => state.currentUser,
  currentUserFeedbacks: (state) => state.currentUser.feedbacks,
  hasAdminPermission: (state) => !!state.currentUser.is_admin,
  admins: (state) => state.admins,
  isAuthenticated: (state) => !!state.currentUser,
  users: (state) => state.users,
  loadingUsers: (state) => state.loading.users,
  loadingUser: (state) => state.loading.user,
  loadingSync: (state) => state.loading.sync,
  loadingUsersChangePassword: (state) => state.loading.users_password,
  loadingUserChangePassword: (state) => state.loading.user_password,
  userDefaultImage: () => require("@/assets/images/user-default.png"),
  dialogChangePasswordUser: (state) => state.dialogChangePasswordUser,
  selectedUser: (state) => state.selectedUser,
  selectedUserName: (state) => state.selectedUser.name,
  totalUsersWithoutGroup: (state, rootState) => {
    const totalUsersWithoutGroup = state.users.reduce((acc, { rule_group }) => {
      const userGroupKeysAmount = rule_group.length;

      if (!userGroupKeysAmount) {
        acc++;
      } else {
        let amountOfKeysThatDoesntBelongToAGroup = 0;
        for (let key of rule_group) {
          if (rootState.groupsKeyNamePairObject[key]) {
            break;
          } else {
            amountOfKeysThatDoesntBelongToAGroup++;
          }
        }

        if (amountOfKeysThatDoesntBelongToAGroup === userGroupKeysAmount) {
          acc++;
        }
      }
      return acc;
    }, 0);
    return totalUsersWithoutGroup;
  },
  showWelcome: (state) =>
    state.currentUser.welcome && state.currentUser.welcome.message,
  showTour: (state) =>
    state.currentUser.welcome && state.currentUser.welcome.tour,
  companyFreshChatConfig: (state) => {
    if (state.currentUser) {
      return {
        email: state.currentUser.email,
        name: state.currentUser.name,
        id: state.currentUser.domain_name,
      };
    }
  },
  editUserShifts: (state) => state.edit.user_shifts,
};

// actions
const actions = {
  getSyncUsersStatus({ dispatch, getters, commit }) {
    const db = getDatabase();
    const processPath = `sync_users/${getters.company.key}`;
    const reference = ref(db, processPath);

    try {
      onValue(reference, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          commit("setFirstSyncUsersStatus", snapshot.val());

          const hasOneAdminRegistered = getters.users.length == 1;

          if (data.in_sync_process == false && hasOneAdminRegistered) {
            dispatch("getUsers");
            dispatch("getGroups");
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  },

  updateWelcome({ commit, state }, payload) {
    const url = "/users/me/welcome";

    return this._vm.$axios.patch(url, payload).then(({ data }) => {
      if (state.currentUser.is_simulated) {
        data.is_simulated = true;
      }
      commit("setCurrentUser", data);
      return Promise.resolve(data);
    });
  },

  async getUser({ commit }, id) {
    commit("setLoadingUser", true);
    await this._vm.$axios.get(`/users/${id}`).then(({ data }) => {
      commit("setSelectedUser", data);
    });
    commit("setLoadingUser", false);
  },
  async getUsers({ commit, dispatch, getters }) {
    commit("setLoadingUsers", true);
    await this._vm.$axios
      .get("/users")
      .then(async ({ data }) => {
        await dispatch("setUsers", data);
        commit("setAdmins", data);

        if (!getters.isSmallClient) {
          setPathDefaultHubSpotTrack();
          loadHubSpotTracking();
          let leadStatus = getters.controlInTrial ? "OPEN" : "Customer";
          if (getters.companyInSuite) {
            const { is_billable } = getters.companyInSuite;
            if (!is_billable) leadStatus = "CONNECTED";
          }

          identifyUserOnHubSpot(getters.currentUser, leadStatus);
        }
      })
      .catch((error) => {
        console.error("vuex: getUsers():", error);
      })
      .finally(() => {
        commit("setLoadingUsers", false);
      });
  },
  setUsers({ getters, commit }, data) {
    commit(
      "setUsers",
      data.map((user) => {
        user.groups = user.rule_group.reduce((acc, key) => {
          if (getters.groupsKeyNamePairObject[key]) {
            acc.push(getters.groupsKeyNamePairObject[key]);
          }
          return acc;
        }, []);
        return user;
      })
    );
  },
  updateUsersOnStore({ dispatch, state }, usersUpdated) {
    let users_updated = state.users.reduce((acc, user) => {
      const matchingUser = usersUpdated.find(
        (updatedUser) => updatedUser.email === user.email
      );
      if (matchingUser) {
        acc.push(matchingUser);
      } else {
        acc.push(user);
      }
      return acc;
    }, []);
    dispatch("setUsers", users_updated);
  },
  async syncUsers({ dispatch, commit }) {
    commit("setLoadingSync", true);
    await this._vm.$axios
      .post("/users/sync", {}, {})
      .then(() => {
        dispatch("getUsers");
      })
      .catch((error) => {
        if (error.response.data.code === "INVALID_GOOGLE_ACCESS_TOKEN") {
          commit("setMissingScopesControl", false);
        }
        console.error("vuex: syncUsers(): ", error);
      })
      .finally(() => {
        commit("setLoadingSync", false);
      });
  },

  setUserOnVacation({ dispatch, commit }, payload) {
    const { key, start_date, end_date } = payload;
    const url = `/users/${key}/vacation`;

    return this._vm.$axios
      .patch(url, { start_date, end_date })
      .then(({ data }) => {
        dispatch("updateUsersOnStore", [data]);
        commit("setSelectedUser", data);
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_vacation_mode");
        commit("showOrHideSnackbar", true);
      })
      .catch((error) => {
        console.error("Vuex setUserOnVacation(): ", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_vacation_mode");
        commit("showOrHideSnackbar", true);
      });
  },

  removeUserVacation({ dispatch, commit }, key) {
    commit("setLoadingUsers", true);
    const url = `/users/${key}/vacation`;

    return this._vm.$axios
      .delete(url)
      .then(({ data }) => {
        dispatch("updateUsersOnStore", [data]);
        commit("setSelectedUser", data);
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_remove_vacation_mode");
        commit("showOrHideSnackbar", true);
      })
      .catch((error) => {
        console.error("Vuex removeVacation(): ", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_remove_vacation_mode");
        commit("showOrHideSnackbar", true);
      })
      .finally(() => {
        commit("setLoadingUsers", false);
      });
  },

  setUsersOnVacationInBatch({ dispatch, commit }, payload) {
    return this._vm.$axios
      .patch("/users/vacation", payload)
      .then(({ data }) => {
        dispatch("updateUsersOnStore", data);
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_vacation_mode");
        commit("showOrHideSnackbar", true);
      })
      .catch((error) => {
        console.error("Vuex setUsersOnVacationInBatch(): ", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_vacation_mode");
        commit("showOrHideSnackbar", true);
      });
  },

  removeUsersVacationInBatch({ dispatch, commit }, emails) {
    commit("setLoadingUsers", true);
    return this._vm.$axios
      .delete("/users/vacation", { data: { users: emails } })
      .then(({ data }) => {
        dispatch("updateUsersOnStore", data);
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_remove_vacation_mode");
        commit("showOrHideSnackbar", true);
      })
      .catch((error) => {
        console.error("Vuex removeUsersVacationInBatch(): ", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_remove_vacation_mode");
        commit("showOrHideSnackbar", true);
      })
      .finally(() => {
        commit("setLoadingUsers", false);
      });
  },

  /* ALTERAR SENHA DE UM ÚNICO USUÁRIO */
  async changeUserPassword({ commit }, user_data) {
    commit("setLoadingChangeUsersPassword", true);
    let errorCode;
    const payload = {
      password: user_data.password,
      force_change: user_data.force_change,
    };
    await this._vm.$axios
      .patch("/users/" + user_data.key + "/change-password", payload)
      .then(() => {
        commit("setDialogChangePasswordUser", false);
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_change_password");
        commit("showOrHideSnackbar", true);
      })
      .catch((error) => {
        console.error("Vuex changeUserPassword(): " + error);
        const errorData = error.response ? error.response.data : null;
        if (errorData && errorData.code) {
          errorCode = errorData.code;
        } else {
          errorCode = "error_change_password";
        }
        commit("setDialogChangePasswordUser", false);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", errorCode);
        commit("showOrHideSnackbar", true);
      })
      .finally(() => {
        commit("setLoadingChangeUsersPassword", false);
      });
    return errorCode;
  },

  async forcePasswordChange({ commit }, mail_list) {
    commit("setLoadingUsers", true);
    commit("setForcePasswordChangeLoading");
    await this._vm.$axios
      .patch("/users/force-password-change", { users: mail_list })
      .then(() => {
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_force_password_change");
        commit("showOrHideSnackbar", true);
      })
      .catch((error) => {
        console.error("Vuex forcePasswordChange(): " + error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_force_password_change");
        commit("showOrHideSnackbar", true);
      })
      .finally(() => {
        commit("setForcePasswordChangeLoading", false);
        commit("setLoadingUsers", false);
      });
  },

  async changeUsersPassword({ commit }, payload) {
    commit("setLoadingChangeUsersPassword", true);
    let errorCode;
    await this._vm.$axios
      .post("/users/change-password", payload)
      .then(() => {
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_change_password");
        commit("showOrHideSnackbar", true);
      })
      .catch((error) => {
        console.error("Vuex forcePasswordChange(): " + error);
        const errorData = error.response ? error.response.data : null;
        if (errorData && errorData.code) {
          errorCode = errorData.code;
        } else {
          errorCode = "error_change_password";
        }
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", errorCode);
        commit("showOrHideSnackbar", true);
      })
      .finally(() => {
        commit("setLoadingChangeUsersPassword", false);
      });
    return errorCode;
  },

  getCurrentUser({ commit }) {
    return this._vm.$axios.get("/users/me").then((response) => {
      const user = response.data;
      commit("setCurrentUser", user);
      return Promise.resolve(user);
    });
  },

  forceLogout({ commit }, key) {
    commit("setLoadingUsers", true);
    return this._vm.$axios
      .get("/users/" + key + "/force-logout")
      .then((response) => {
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_logout");
        commit("showOrHideSnackbar", true);
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.error("Vuex forceLogout(): ", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_logout");
        commit("showOrHideSnackbar", true);
        return Promise.catch(error);
      })
      .finally(() => {
        commit("setLoadingUsers", false);
      });
  },

  grantSingleAccess({ commit }, data) {
    return this._vm.$axios
      .post(`/users/${data.userKey}/single-access`, data.payload)
      .then((response) => {
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_grant_single_access");
        commit("showOrHideSnackbar", true);
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.error("Vuex grantSingleAccess(): ", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_grant_single_access");
        commit("showOrHideSnackbar", true);
        return Promise.catch(error);
      });
  },

  removeSingleAccess({ commit }, key) {
    return this._vm.$axios
      .delete(`/users/${key}/single-access`)
      .then((response) => {
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_remove_single_access");
        commit("showOrHideSnackbar", true);
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.error("Vuex grantSingleAccess(): ", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_remove_single_access");
        commit("showOrHideSnackbar", true);
        return Promise.catch(error);
      });
  },

  forceUsersLogout({ commit }, emails) {
    commit("setLoadingUsers", true);

    if (!emails.users.length) {
      commit("setSnackbarColor", "error");
      commit("setSnackbarMessage", "error_logout_users_suspended");
      commit("showOrHideSnackbar", true);
      commit("setLoadingUsers", false);

      return;
    }

    return this._vm.$axios
      .post("/users/force-logout", emails)
      .then(() => {
        commit("setSnackbarColor", "success");
        commit("setSnackbarMessage", "success_users_logout");
        commit("showOrHideSnackbar", true);
        commit("setLoadingUsers", false);
      })
      .catch((error) => {
        console.error("Vuex forceLogout(): ", error);
        commit("setSnackbarColor", "error");
        commit("setSnackbarMessage", "error_logout");
        commit("showOrHideSnackbar", true);
        commit("setLoadingUsers", false);
      });
  },

  async getUserHubSpotToken() {
    const url = "/users/hubspot-token";
    let token = "";
    await this._vm.$axios
      .get(url)
      .then(({ data }) => {
        token = data;
      })
      .catch((err) => {
        console.error("Vuex: getUserHubSpotToken()", err);
      });
    return token;
  },

  async syncUsersTask() {
    const url = "/users/sync-users-task";
    await this._vm.$axios.get(url).catch((err) => {
      console.error("Vuex: syncUsersTask()", err);
    });
  },
};

// mutations
const mutations = {
  setForcePasswordChangeLoading(state, status = true) {
    state.loading.force_password_change = status;
  },
  setLoadingChangeUsersPassword(state, status) {
    state.loading.users_password = status;
  },
  setLoadingChangeUserPassword(state, status) {
    state.loading.user_password = status;
  },
  setLoadingSync(state, status) {
    state.loading.sync = status;
  },
  setLoadingUsers(state, status) {
    state.loading.users = status;
  },
  setLoadingUser(state, status) {
    state.loading.user = status;
  },
  setUsers(state, data) {
    state.users = data;
  },
  setDialogChangePasswordUser(state, status) {
    state.dialogChangePasswordUser = status;
  },
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
  },
  setSelectedUser(state, user) {
    state.selectedUser = user;
  },

  setAdmins(state, users = []) {
    state.admins = users.filter((user) => user.allowed);
  },

  setNewAdmin(state, admin) {
    state.admins.push(admin);
  },

  removeAdminFromStore(state, key) {
    const admins = state.admins.filter((admin) => {
      return admin.key != key;
    });

    state.admins = admins;
  },

  setEditUserMode(state, info) {
    state.edit[info.key] = info.value;
  },

  updateCurrentUserFeedbacks(state, feedbacks) {
    state.currentUser.feedbacks = feedbacks;
  },

  setFirstSyncUsersStatus(state, value) {
    state.firstSyncUsersStatus = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-dialog v-model="dialog" width="600" scrollable>
    <template #activator="{ on: dialog }">
      <v-tooltip top>
        <template #activator="{ on: tooltip }">
          <v-btn
            fab
            fixed
            bottom
            right
            dark
            color="light-blue darken-4"
            elevation="0"
            class="mt-3"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon v-text="'mdi-face-agent'" />
          </v-btn>
        </template>
        <span>{{ $ml.get("help") }}</span>
      </v-tooltip>
    </template>

    <v-card class="pt-3">
      <v-card-title class="text-h5 primary--text mb-4">
        {{ $ml.get("help") }}
      </v-card-title>

      <v-list class="px-5">
        <v-list-item
          link
          v-for="({ icon, title, action }, index) in options"
          :key="index"
          class="py-2 grey lighten-4 secondary--text text--lighten-2 mb-4"
          @click="open(action)"
        >
          <v-list-item-action class="mr-5">
            <v-avatar color="accent">
              <v-icon v-text="icon" size="26" color="white" />
            </v-avatar>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap break-word normal-line-height font-weight-medium text-h6 mt-1"
              v-html="$ml.get(title)"
            />
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'mdi-arrow-right'" />
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary lighten-1" text @click="dialog = false">
          {{ $ml.get("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { openInNewTab, initFreshChat, isNuvemClient } from "@/helpers/utils";
import { controlFaq, controlVideosOnYoutube } from "@/helpers/links";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HelpDialog",

  data() {
    return {
      controlFaq,

      controlVideosOnYoutube,

      dialog: false,
    };
  },

  computed: {
    ...mapGetters([
      "is_support_chat_available",
      "isSmallClient",
      "companyInSuite",
    ]),

    options() {
      return [
        {
          icon: "mdi-chat-question-outline",
          title: "frequently_questions",
          action: "faq",
          show: true,
        },
        {
          icon: "mdi-video-outline",
          title: "tutorial_video",
          action: "youtube",
          show: true,
        },
        {
          icon: "mdi-face-agent",
          title: "receive_support_label",
          action: "supportChat",
          show: this.is_support_chat_available && !this.isSmallClient,
        },
      ].filter((item) => item.show);
    },
  },

  methods: {
    ...mapActions(["getHubspotToken"]),
    initFreshChat,
    isNuvemClient,

    open(action) {
      if (action === "supportChat") {
        const { reseller_token } = this.companyInSuite;
        if (this.isNuvemClient(reseller_token)) {
          this.initFreshChat();
        } else {
          this.getHubspotToken();
        }
        setTimeout(() => {
          this.dialog = false;
        }, 3000);
      } else if (action === "youtube") {
        openInNewTab(this.controlVideosOnYoutube);
        this.dialog = false;
      } else {
        openInNewTab(this.controlFaq);
        this.dialog = false;
      }
    },
  },
};
</script>

import { testCompanies } from "@/helpers/variables";
import router from "../../router";
import { registerUserToHubspotChat } from "@/helpers/hubspot";
import suiteAPIClient from "@/gateways/suiteapi";
import { dateIsToday } from "@/helpers/utils2";

function parseToken(token) {
  if (!token) return;

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(window.atob(base64));
  return payload;
}

function isTokenExpired(token) {
  if (!token) return;

  const tokenData = parseToken(token);

  if (!tokenData) return true;

  const currentTimestamp = Math.floor(Date.now() / 1000);
  return tokenData.exp < currentTimestamp;
}

const state = () => ({
  sso_is_active: null,
  company_in_suite: null,
  control_plan: null,
  plan_status_alert: "",
  has_control_plan: null,
  missingScopesControl: true,
  company: {
    main_domain: "",
    domains_list: [],
    default_pass: "",
    logo: "",
    primary_color: "",
    secondary_color: "",
    logo_height: "",
    logo_width: "",
    ponto_mais_token: "",
    password_strength: {},
    new_sso_active: false,
    oauth: "",
    federated_domains: [],
    certificate: "",
    senior_credentials: {},
  },
  hasPontoMaisIntegration: undefined,
  pontoMaisToken: undefined,
  IsScheduleMeetingSnackbarVisible: false,
  hasSeniorCredentials: false,
  scheduleActivationInfo: {},
});

const getters = {
  doNotHaveCNPJ: (state) =>
    state.companyInSuite ? state.companyInSuite.cnpj : false,
  isSmallClient: (state, getters) => getters.users.length <= 5,
  hasScheduleActivationInfo: (state) => state.scheduleActivationInfo.created_at,
  scheduleActivationInfo: (state) => state.scheduleActivationInfo,

  isSsoActive: (state) => state.sso_is_active,

  controlPlan: (state) => state.control_plan,

  usersNumber: (state) =>
    state.company_in_suite && state.company_in_suite.users_number,

  controlInTrial: (state) =>
    state.control_plan && state.control_plan.status == "TRIAL",

  controlInTrialOrInexistent: (state) => {
    if (state.has_control_plan === null) {
      return false;
    }
    return !state.control_plan || state.control_plan.status == "TRIAL";
  },

  passwordStrength: (state) => state.company.password_strength,

  companyInSuite: (state) => state.company_in_suite,

  currentCompanyPlan: (state) =>
    state.company_in_suite ? state.company_in_suite.current_plan : null,

  companyTags: (state) =>
    state.company_in_suite ? state.company_in_suite.tag : [],

  planStatusAlert: (state) => state.plan_status_alert,

  hasControlPlan: (state) => state.has_control_plan,

  missingScopesControl: (state) => state.missingScopesControl,

  is_support_chat_available: (state) =>
    state.company_in_suite
      ? state.company_in_suite.reseller_company.is_support_chat_available
      : false,

  company: (state) => state.company,

  hasPontoMaisIntegration: (state) => state.hasPontoMaisIntegration,

  pontoMaisToken: (state) => state.company.ponto_mais_token,

  defaultPassword: (state) => state.company.default_pass,

  isTester: (state) =>
    testCompanies.includes(state.company && state.company.main_domain),

  mainDomain: (state) => state.company.main_domain,

  isMicrosoft: (state) => state.company.oauth === "microsoft",

  domainsList: (state) =>
    ((state.company && state.company.domains_list) || []).filter(
      (domain) => !domain.includes(".onmicrosoft.")
    ),

  hasIntermediaryPartner: (state) =>
    state.company_in_suite.reseller_company &&
    state.company_in_suite.reseller_company.type === "INTERMEDIARY",

  isSalesChat(_, getters) {
    const { controlInTrialOrInexistent, companyInSuite } = getters;
    if (companyInSuite) {
      return controlInTrialOrInexistent || companyInSuite.suspended;
    }

    return controlInTrialOrInexistent;
  },

  IsScheduleMeetingSnackbarVisible: (state) =>
    state.IsScheduleMeetingSnackbarVisible,
  hasSeniorCredentials: (state) => state.hasSeniorCredentials,
};

const actions = {
  getCompanySsoStatus({ commit }) {
    this._vm.$axios
      .get("/companies/sso")
      .then(({ data }) => {
        commit("setSsoStatus", data.sso_is_active);
      })
      .catch((error) => {
        console.error("vuex: getCompanySsoStatus():", error);
      });
  },

  getControlPlanStatus() {
    return this._vm.$axios.get("/companies/permission", {
      params: { product: "conecta_control" },
    });
  },

  getScopes({ commit }) {
    let urlControlScopes = `${process.env.VUE_APP_API_BASE_URL}/auth/scopes`;

    this._vm.$axios
      .get(urlControlScopes)
      .then(() => {
        commit("setMissingScopesControl", true);
      })
      .catch(() => {
        commit("setMissingScopesControl", false);
      });
  },

  getCompanyInSuite({ commit, dispatch }) {
    this._vm.$axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/companies/company-in-suite`)
      .then(async ({ data }) => {
        if (data.is_banned) {
          dispatch("logout");
        }
        if (data.suspended) {
          router.push({ name: "Suspended" });
        }
        commit("setCompanyInSuite", data);
      })
      .catch(() => {
        dispatch("logout");
      });
  },

  getCurrentCompany({ dispatch, commit }) {
    this._vm.$axios.get("/companies/current_company").then(({ data }) => {
      commit("setCompany", data);
      commit("setSsoStatus", data.sso_is_active);
      commit(
        "setHasSeniorCredentials",
        Object.keys(data.senior_credentials).length > 0
      );
      commit("setScheduleActivationInfo", data.activation_info);

      if (dateIsToday(data.created_at)) {
        dispatch("getSyncUsersStatus");
      }
    });
  },

  async getHubspotToken({ dispatch, getters }) {
    if (getters.isSmallClient) return;

    let hubToken = JSON.parse(localStorage.getItem("hubspotToken"));

    if (!hubToken || isTokenExpired(hubToken)) {
      hubToken = await dispatch("getUserHubSpotToken");
      localStorage.setItem("hubspotToken", JSON.stringify(hubToken));
    }
    const { currentUser, isSalesChat } = getters;
    registerUserToHubspotChat(currentUser.email, hubToken, isSalesChat);
  },

  async checkPontoMaisIntegration({ commit }) {
    try {
      await this._vm.$axios.get(
        "/companies/permission?product=integracao_ponto_mais"
      );
      commit("setHasPontoMaisIntegration", true);
    } catch {
      commit("setHasPontoMaisIntegration", false);
    }
  },

  async getPontoMaisToken({ commit }) {
    try {
      const { data } = await this._vm.$axios.get("/companies/ponto-mais-token");
      commit("setPontoMaisToken", data.ponto_mais_token);
    } catch {
      commit("setPontoMaisToken", null);
    }
  },

  editDefaultPassword({ commit, dispatch }, payload) {
    return this._vm.$axios
      .put("/companies/default-pass", payload)
      .then(({ data }) => {
        commit("setNewCompanyValue", data);
        dispatch("setSnackbarDefault", {
          message: "success_update_default_password_message",
        });
      })
      .catch(() => {
        dispatch("setSnackbarDefault", {
          color: "error",
          message: "error_update_default_password_message",
        });
      });
  },

  updateCompanyInSuite(_, payload) {
    let url = "/integrations/conecta-control/update/company";
    return suiteAPIClient.put(url, payload);
  },

  async syncCompanyUos({ commit }) {
    await this._vm.$axios
      .patch("/companies/uos/sync", {}, {})
      .then(({ data }) => {
        commit("setCompanyUos", data);
      })
      .catch((error) => {
        if (error.response.data.code === "INVALID_GOOGLE_ACCESS_TOKEN") {
          commit("setMissingScopesControl", false);
        }

        console.error("vuex: syncCompanyUos(): ", error);
      });
  },
};

const mutations = {
  setSsoStatus(state, data) {
    state.sso_is_active = data;
  },

  setControlPlan(state, data) {
    state.control_plan = data;
  },

  setPlanStatusAlert(state, data) {
    state.plan_status_alert = data;
  },

  setHasControlPlan(state, data) {
    state.has_control_plan = data;
  },

  setMissingScopesControl(state, data) {
    state.missingScopesControl = data;
  },

  setCompany(state, data) {
    state.company = data;
  },

  setCompanyInSuite(state, data) {
    state.company_in_suite = data;
  },

  setNewCompanyValue(state, payload) {
    for (let key in payload) {
      if (state.company[key] !== undefined) {
        state.company[key] = payload[key];
      }
    }
  },

  setHasPontoMaisIntegration(state, data) {
    state.hasPontoMaisIntegration = data;
  },

  setPontoMaisToken(state, data) {
    state.pontoMaisToken = data;
  },

  setMeetingSnackbarVisibility(state, status = false) {
    state.IsScheduleMeetingSnackbarVisible = status;
  },

  updateCompanyNewSsoIsActive(state, status = false) {
    state.company.new_sso_active = status;
  },

  setHasSeniorCredentials(state, credentials) {
    state.hasSeniorCredentials = credentials;
  },
  setScheduleActivationInfo(state, data) {
    state.scheduleActivationInfo = data;
  },

  setCompanyUos(state, data) {
    state.company.uos = data;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};

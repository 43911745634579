export const frequentlyAskedQuestions =
  "https://conectasuite.com/perguntas-frequentes-conecta-suite/#h-conecta-control";
export const helpMoreLink =
  "https://docs.google.com/presentation/d/e/2PACX-1vQmkEYretvfgh3jqxzdKT_ROzRwQpzB4lKTGcDjksJFo5r0xPjrF2rnVnH2PXmHVXzAiZPGQ_NaGIeT/pub?start=false&loop=false&delayms=3000&slide=id.geb1f20f728_0_308";
export const linkAdminGoogleApps =
  "https://admin.google.com/ac/apps/gmail/marketplace/appdetails/";
export const suportEmail = "suporte@conectasuite.com";
export const billingAddPlan = `${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan/PRO-PLA-122021`; // COD SKU PLANO ENTERPRISE OU PROFESSIONAL
export const selfcheckoutLink = `${process.env.VUE_APP_SUITE_BASE_URL}/billing/upgrade`;
export const companyProfileLink = `${process.env.VUE_APP_SUITE_BASE_URL}/company`;
export const conectaSignLink = `${process.env.VUE_APP_SUITE_BASE_URL}/sign`;
export const suiteLink = `${process.env.VUE_APP_SUITE_BASE_URL}`;
export const adIntegrationDocLink =
  "https://docs.google.com/presentation/d/e/2PACX-1vRjfB50VqAb5-xLj3S8HTumnmemIziB2EybZT8gzeFGxy2iHK_DQZ0ScAro8yuYJ6F05DFmhBHiiTjq/pub?start=false&loop=false&delayms=60000&slide=id.p";
export const pontoMaisIntegrationDocLink =
  "https://docs.google.com/document/d/1scearJ6b_MZ0vrzIQyRfxNuPtTE4w85WO635I5QiYwU/edit";
export const tangerinoIntegrationDocLink =
  "https://confluence.external-share.com/content/472966/como_integrar_o_conecta_control_com_o_sólides_tangerino";
export const adSyncSetupLink =
  "https://drive.google.com/u/0/uc?id=1tqo8jQuCoIXWltR6c4FHiyiIj_IDbXij&export=download";

export const pontomaisAutologoutWebhookLink =
  "https://api-pontomais.conectacontrol.com/?Token=";

export const calendarSchedule =
  "https://corp.conectasuite.com/meetings/app-conectasuite/lais?uuid=0d190912-fd97-4113-9f36-706635ac704a";

export const commercial_responsable_img =
  "https://lh3.googleusercontent.com/d/1gm_GRtN37SI_R7mPUUKlPDbA18YvxzpT?authuser=0";

export const seniorExternalIntegrationLink =
  "https://confluence.external-share.com/content/fcb07b65-8bdd-44e4-8588-ca14e9ecddcd";

export const controlFaq =
  "https://conectasuite.com/perguntas-frequentes-conecta-suite/#h-conecta-control";

export const controlVideosOnYoutube =
  "https://www.youtube.com/@ConectaNuvem/search?query=Control";

export const superlogicaClientPage =
  "https://brlgestao.superlogica.net/clients/areadocliente/clientes/";
